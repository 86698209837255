import { purchasesActions } from ".";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../utils/functions";
import { appActions } from "../app";
import { getSuppliersService } from "../app/supplier/supplierService";
import { getPurchasesService, searchPurchaseService } from "./purchaseService";

// GET ALL
export const getPurchasesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getPurchasesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(
          purchasesActions.setPurchases({
            purchases: res.data,
            count: res.total,
            fetched: true,
          })
        );
        dispatch(purchasesActions.setFetchedPurchases(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportPurchasesAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getPurchasesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchPurchasesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchPurchaseService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setSuppliers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedStaff(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchElementsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(purchasesActions.setFetchingFormElements(true));

      // FETCH CUSTOMERS
      const suppliers = await getSuppliersService(query, token);
      if (requestFailed(suppliers)) {
        dispatch(setResponseAction(suppliers, "fail", suppliers.message));
      } else {
        dispatch(purchasesActions.setSuppliers(suppliers.data));
      }

      dispatch(purchasesActions.setFetchedFormElements(true));
      dispatch(purchasesActions.setFetchingFormElements(false));
    } catch (err) {
      console.log(err);
    }
  };
};
