import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "..";
import {
  getCustomersService,
  searchCustomerService,
  updateCustomerService,
  addCustomerService,
  deleteCustomerService,
} from "./customerService";

// GET ALL
export const getCustomersAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getCustomersService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setCustomers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedCustomers(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportCustomersAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getCustomersService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchCustomerAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchCustomerService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setCustomers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedStaff(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// UPDATE
export const updateCustomerAction = (
  customerId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      const res = await updateCustomerService(customerId, data, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(setResponseAction(res, "success", res.message));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// ADD
export const addCustomerAction = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await addCustomerService(data, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(setResponseAction(res, "success", res.message));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// DELETE
export const deleteCustomerAction = (customerId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await deleteCustomerService(customerId, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(setResponseAction(res, "success", res.message));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
