import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../interfaces/user";

export interface AuthState {
  isFetching: boolean;
  isAuth: boolean;
  token: String;
  user: User | null;
  status: String;
}

const authSlice = createSlice({
  name: "authentication",
  initialState: {
    isFetching: false,
    isAuth: false,
    token: "",
    user: null,
    status: "",
  } as AuthState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.data?.user;
      state.status = action.payload.status;
      state.isAuth = true;
    },
    logout(state) {
      // store.remove('x-auth-token');
      state.token = "";
      state.status = "";
      state.user = null;
      state.isAuth = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
