import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import AppsIcon from '@mui/icons-material/Apps';

export interface DynamicListViewProps {
  viewGrid: boolean;
  setViewGrid: Function;
}

const DynamicListView = (props: DynamicListViewProps) => {
  return (
    <div className="flex justify-end mx-4">
      <div className="p-1 rounded-md bg-gray-200">
        <AppsIcon
          className="mx-1 cursor-pointer"
          style={{ backgroundColor: props.viewGrid ? 'white' : 'transparent' }}
          onClick={() => props.setViewGrid(true)}
        />
        <ViewHeadlineIcon
          className="mx-1 rounded cursor-pointer"
          style={{ backgroundColor: props.viewGrid ? 'transparent' : 'white' }}
          onClick={() => props.setViewGrid(false)}
        />
      </div>
    </div>
  );
};

export default DynamicListView;
