import "../../components/form_styles.css";
import { useTranslation } from "../../i18n";
import { appActions } from "../../store/app";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../components/GenericForm";
import { addAction, updateAction } from "../../store/app/appActions";
import { addSupplierService } from "../../store/app/supplier/supplierService";
import Storage from "../../utils/storage";
import { Form } from "antd";
import {
  addShopService,
  updateShopService,
} from "../../store/app/shop/shopServices";
import { ShopState, shopActions } from "../../store/shop";
import { useEffect } from "react";

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  onSubmit: Function;
  shop?: any;
  styles?: string;
  confirmText?: string;
}

const AddShop = (props: GenericFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const shopState: ShopState = useSelector((state: any) => state.shop);

  const shopCategories: any = [
    {
      name: "Pharmacy",
      _id: "pharmacy",
    },
    {
      name: "Alimentation",
      _id: "alimentation",
    },
    {
      name: "Papeterie",
      _id: "papeterie",
    },
    {
      name: "Quincallerie",
      _id: "quincallerie",
    },
  ];

  useEffect(() => {
    if (props.shop) {
      form.setFieldsValue({
        name: props?.shop.name,
        address: props?.shop.address,
        tinNumber: props?.shop.tinNumber,
        tags: props?.shop.tags,
      });
    }
  }, []);

  const onFinish = (values: any) => {
    values.company = Storage.getUser()?.company?._id;

    if (props.shop) {
      dispatch(
        updateAction(
          updateShopService,
          props?.shop?._id,
          values,
          token,
          () => props.close(),
          () => {
            props.close();
            dispatch(shopActions.setFetchedShops(false));
          }
        )
      );
    } else {
      dispatch(
        addAction(
          addShopService,
          values,
          token,
          () => props.close(),
          () => {
            props.close();
            dispatch(shopActions.setFetchedShops(false));
          }
        )
      );
    }
  };

  const formElements = [
    {
      name: "name",
      label: "Name",
      type: "text",
      rules: [{ required: true, message: "Please input your username!" }],
    },
    {
      name: "address",
      label: "Address",
      type: "text",
    },
    {
      name: "tinNumber",
      label: "Tin Number",
      type: "number",
    },
    {
      name: "shopCategory",
      label: "Shop Category",
      type: "select",
      options: shopCategories,
      rules: [{ required: true, message: "Please select shop category" }],
    },
  ];

  return (
    <GenericForm
      open={props.open}
      close={() => props.close()}
      title="New Shop"
      styles="vertical"
      form={form}
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default AddShop;
