import { Typography } from "antd";
import { useEffect, useState } from "react";
import { getSuppliersAction } from "../../../store/app/supplier/supplierActions";
import Storage from "../../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import LoadingContainer from "../../../components/LoadingContainer";
import { Supplier } from "../../../interfaces/supplier";
import styles from "../../index.module.scss";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SupplierCardValues from "./SupplierCardValues";
import SearchComponent from "../../../components/SearchComponent";
import { useTranslation } from "../../../i18n";
import { LoadingButton } from "@mui/lab";
import NewSupplier from "./NewSupplier";
import { renderMoney } from "../../../utils/functions";
import NoData from "../../../components/NoData";

const Suppliers = () => {
  const token = Storage.getToken();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const { t } = useTranslation();

  useEffect(() => {
    if (!appState.fetchedSuppliers) {
      dispatch(
        getSuppliersAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }
  }, [appState.fetchedSuppliers]);

  const exportExcelHandler = () => {};

  return (
    <>
      <Box className={styles.searchComponent}>
        <SearchComponent setSearchText={setSearchText} />
        <Box style={{ display: "flex" }}>
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
          <Button
            className={styles.primaryButton}
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            onClick={() => {
              dispatch(appActions.setOpenForm({ open: true, action: "add" }));
            }}
          >
            Add Supplier
          </Button>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <div className="flex flex-wrap mt-2 overflow-y-scroll h-[70vh]">
          {appState.suppliers.length > 0 &&
            appState.suppliers.map((supplier: Supplier) => (
              <Box key={supplier._id} className={styles.supplierCard}>
                <Typography className={styles.userCardName}>
                  {supplier.name}
                </Typography>
                <SupplierCardValues label="Email" value={supplier.email ?? "N/A"} />
                <SupplierCardValues label="Phone" value={supplier.phone ?? "N/A"} />
                <SupplierCardValues
                  label="Address"
                  value={supplier.address ?? "N/A"}
                />
                <SupplierCardValues
                  label="Debt"
                  value={renderMoney(supplier.debt)}
                />
                <Box style={{ display: "flex", justifyContent: "end" }}>
                  <VisibilityIcon />
                  <EditIcon />
                  <DeleteIcon />
                </Box>
              </Box>
            ))}
          {appState.suppliers.length === 0 && <NoData />}
        </div>
      )}
      {appState.openForm.open && (
        <NewSupplier
          open={appState.openForm.open}
          close={() => {
            dispatch(appActions.setOpenForm({ open: false, action: "" }));
          }}
          styles="vertical"
          title={"New Supplier"}
          onSubmit={() => {}}
        />
      )}
    </>
  );
};

export default Suppliers;
