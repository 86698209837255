import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js';
import { registerables } from 'chart.js';

ChartJS.register(...registerables);

const LineChart = (props: any) => {
  let { chartData } = props;
  return <>{chartData?.labels?.length > 0 && <Line data={chartData} />}</>;
};

export default LineChart;
