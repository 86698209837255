import { Avatar, Box, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../../utils/storage";
import { AppState, appActions } from "../../store/app";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styles from "../index.module.scss";
import LoadingContainer from "../../components/LoadingContainer";
import TableComponent from "../../components/TableComponent";
import { Shop, ShopRow, shopColumns } from "../../interfaces/shop";
import NewUser from "./NewUser";
import { getUsersAction } from "../../store/app/user/userActions";
import { Typography } from "antd";
import { User } from "../../interfaces/user";
import UserCardValues from "./UserCardValues";
import DynamicListView from "../../components/DynamicListView";
import SearchComponent2 from "../../components/SearchComponent2";
import { ShopState } from "../../store/shop";

const UsersSettings = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newUser, setNewUser] = useState(false);
  const [viewGrid, setViewGrid] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [shop, setShop] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const appState: AppState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);

  useEffect(() => {
    if (!appState.fetchedUsers) {
      dispatch(
        getUsersAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }
  }, [appState.fetchedUsers]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedStaff(false));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(appActions.setFetchedStaff(false));
  };

  const createData = (shop: Shop): ShopRow => {
    return {
      name: shop.name,
      address: shop.address,
      status: shop.status,
      category: shop?.shopCategory,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setShop(shop);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState.shops?.map((shop: Shop) => createData(shop));

  return (
    <>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="flex items-center justify-between my-10">
            <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
            <SearchComponent2
              placeholder="Search User"
              setSearchText={setSearchText}
            />
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              className={styles.primaryButton}
              onClick={() => setNewUser(true)}
            >
              Add User
            </Button>
          </div>

          <Box className={styles.productsContainer}>
            {appState.users.map((user: User) => (
              <Box className={styles.userCard}>
                <Box className={styles.userCardLeft}>
                  <Avatar
                    style={{ height: "4rem", width: "4rem" }}
                    alt="Remy Sharp"
                    src={user?.image?.url}
                  />
                </Box>
                <Box className={styles.userCardRight}>
                  <Typography className="text-center font-bold mb-2">
                    {user.names}
                  </Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                  <UserCardValues label="Email" value={user.email} />
                  <UserCardValues label="Phone" value={user.phone} />
                  <UserCardValues label="Role" value={user.role} />
                </Box>
              </Box>
            ))}
          </Box>
          {!viewGrid && (
            <TableComponent
              rows={rows}
              columns={shopColumns}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
          {newUser && (
            <NewUser
              open={newUser}
              close={() => setNewUser(false)}
              title={"New User"}
              onSubmit={() => {}}
            />
          )}
        </>
      )}
    </>
  );
};

export default UsersSettings;
