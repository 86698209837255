import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./english";
import translationsKin from "./kinyarwanda";
import translationsFr from "./french";
import translationsSw from "./swahili";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEn },
    fr: { translation: translationsFr },
    kin: { translation: translationsKin },
    sw: { translation: translationsSw },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export { useTranslation } from "react-i18next";

export default i18n;
