import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getSalesService = async (query: string, token: string) => {
  console.log("query", `${SERVER_URL}/sales${query}`);
  return await HttpRequest.get(`${SERVER_URL}/sales${query}`, token);
};

export const searchSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/sales/search${query}`, token);
};

export const updateSalesService = async (
  saleId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/sales/one/${saleId}`,
    data,
    token
  );
};

export const addSalesService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/sales`, data, token);
};

export const addToSalesListService = async (
  saleId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/sales/one/${saleId}`,
    data,
    token
  );
};

export const deleteSalesService = async (saleId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/sales/one/${saleId}`, token);
};
