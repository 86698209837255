import { Box, Divider, Grid } from "@mui/material";
import styles from "./index.module.scss";
import NavMenu from "./NavMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../assets/images/logo.png";
import NavItem from "./NavItem";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "../i18n";
import SupportIcon from '@mui/icons-material/Support';
import { useLocation, Link } from "react-router-dom";
import AppBar from "./AppBar";

const Layout = (props: any) => {
  const location = useLocation();
  const { t } = useTranslation();
  const secondaryNavItems = [
    {
      label: t("settings"),
      icon: <SettingsIcon style={{ color: "currentColor" }} />,
      path: "/settings",
    },
    {
      label: t("support"),
      icon: <SupportIcon style={{ color: "currentColor" }} />,
      path: "/support",
    },
    {
      label: t("logout"),
      icon: <LogoutIcon style={{ color: "currentColor" }} />,
      path: "/login",
    },
  ]
  return (
    <Grid container spacing={0}>
      <Grid item xs={2} className={styles.sideNav}>
        <Box className={styles.logo_container}>
          <Box className={`${styles.center} ${styles.imageContainer}`}>
            <img className={styles.image} alt="logo" src={logo} />
          </Box>
        </Box>
        <div className="px-8">
          <Divider />
        </div>
        <NavMenu />
        <div className="px-8 my-4">
          <Divider />
        </div>
        <Link to="/settings" className={styles.link} onClick={() => {}}>
          <NavItem
            nav={secondaryNavItems[0]}
            selected={"/settings" === location.pathname}
          />
        </Link>
        {/* <Link to="/support" className={styles.link} onClick={() => {}}>
          <NavItem
            nav={secondaryNavItems[1]}
            selected={"/support" === location.pathname}
          />
        </Link> */}
        <div className="px-8 my-4">
          <Divider />
        </div>
        <div>
        <NavItem
            nav={secondaryNavItems[2]}
            selected={"/logout" === location.pathname}
          />
        </div>
      </Grid>
      <Grid item xs={10} className={styles.main}>
      <AppBar pages={props.pages} activeTab={props.activeTab} setActiveTab={props.setActiveTab} />
      <div className="mx-6">
        {props.children}
      </div>
      </Grid>
    </Grid>
  );
};

export default Layout;
