import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getPurchasesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/purchase${query}`, token);
};

export const searchPurchaseService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/purchase/search${query}`, token);
};

export const updatePurchaseService = async (
  purchaseId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/purchase/one/${purchaseId}`,
    data,
    token
  );
};

export const updatePurchaseItemService = async (
  purchaseItemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/purchaseitem/one/${purchaseItemId}`,
    data,
    token
  );
};

export const addPurchaseService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/purchase`, data, token);
};

export const addToPurchaseListService = async (
  purchaseId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/purchase/one/${purchaseId}`,
    data,
    token
  );
};

export const deletePurchaseService = async (
  purchaseId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/purchase/one/${purchaseId}`,
    token
  );
};

export const deletePurchaseItemService = async (
  purchaseId: string,
  purchaseItemId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/purchase/one/remove/${purchaseId}/${purchaseItemId}`,
    token
  );
};