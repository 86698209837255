import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import {
  getProductItemHistoryService,
  getProductItemsService,
  getStockService,
} from "./productItemService";
import { productActions } from "..";

// GET ALL
export const getStockAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getStockService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setStock(res.data));
        dispatch(appActions.setCount(res.total));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductItemsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getProductItemsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setProductItems(res.data));
        dispatch(appActions.setCount(res.total));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportPriceListsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getProductItemsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// GET HISTORY
export const getProductItemHistoryAction = (
  productItemId: string,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(productActions.setFetchingHistory(true));
      const res = await getProductItemHistoryService(productItemId, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setHistory(res.data));
      }
      dispatch(productActions.setFetchingHistory(false));
    } catch (err) {
      console.log(err);
    }
  };
};
