import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getCompanysService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/company${query}`, token);
};

export const searchCompanyService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/company/search${query}`, token);
};

export const updateCompanyService = async (
  companyId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/company${companyId}`,
    data,
    token
  );
};

export const addCompanyService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/company`,
      data,
    });

    return res.data;
  } catch (err: any) {
    return err;
  }
};

export const deleteCompanyService = async (
  companyId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/company${companyId}`, token);
};
