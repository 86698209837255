import { Skeleton } from "@mui/material";

const ListLoadingItem = () => {
  return (
    <>
      <div className="p-2 mx-4 mt-8 mb-8 rounded-md cursor-pointer shadow-md">
        <Skeleton />
        <div className="w-1/3">
          <Skeleton />
        </div>
      </div>
      <div className="p-2 mx-4 mb-8 rounded-md cursor-pointer shadow-md">
        <Skeleton />
        <div className="w-1/3">
          <Skeleton />
        </div>
      </div>
      <div className="p-2 mx-4 mb-8 rounded-md cursor-pointer shadow-md">
        <Skeleton />
        <div className="w-1/3">
          <Skeleton />
        </div>
      </div>

      <div className="p-2 mx-4 mb-8 rounded-md cursor-pointer shadow-md">
        <Skeleton />
        <div className="w-1/3">
          <Skeleton />
        </div>
      </div>
    </>
  );
};

export default ListLoadingItem;
