import { Checkbox } from "@mui/material";

const FormCheckBox = (props: any) => {
  return (
    <Checkbox
      // checked={props?.element?.checked}
      // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      //     props?.element?.setChecked(event.target.checked);
      //   }}
      inputProps={{ "aria-label": "controlled" }}
      // {...props?.element?.register(props?.element?.identifier, props?.element?.validationObject)}
    />
  );
};

export default FormCheckBox;
