import { Box, Typography } from "@mui/material";
import styles from "./index.module.scss";
import noData from "../assets/icons/no_data.svg";

const NoData = () => {
  return (
    <Box className={styles.noDataContainer}>
      <img src={noData} className={styles.noDataSvg} alt="no data" />
      <Typography>No Data</Typography>
    </Box>
  );
};

export default NoData;
