import "../../../components/form_styles.css";
import { useTranslation } from "../../../i18n";
import { appActions } from "../../../store/app";
import { useDispatch } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { addAction } from "../../../store/app/appActions";
import { addSupplierService } from "../../../store/app/supplier/supplierService";
import Storage from "../../../utils/storage";
import { Form } from "antd";

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  onSubmit: Function;
  styles?: string;
  confirmText?: string;
}

const NewSupplier = (props: GenericFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();

  console.log("Ttttototootot ", token);

  const onFinish = (values: any) => {
    if (!values.address) delete values.address;
    if (!values.phone) delete values.phone;
    if (!values.tinNumber) delete values.tinNumber;
    if (!values.email) delete values.email;
    values.company = Storage.getUser()?.company?._id;

    dispatch(
      addAction(
        addSupplierService,
        values,
        token,
        () => {},
        () => dispatch(appActions.setFetchedSuppliers(false))
      )
    );
  };

  const phoneValidator = (_: any, value: string) => {
    if (!value || /^(\+?\d+)?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid phone number");
  };

  const formElements = [
    {
      name: "name",
      label: "Names",
      type: "text",
      rules: [{ required: true, message: "Please input your username!" }],
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      rules: [{ type: "email", message: "The input is not a valid email!" }],
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      rules: [{ validator: phoneValidator }],
    },
    { name: "address", label: "Address", type: "text" },
    { name: "tinNumber", label: "Tin Number", type: "text" },
  ];

  return (
    <GenericForm
      open={props.open}
      close={() => props.close()}
      title="New Supplier"
      styles="vertical"
      form={form}
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default NewSupplier;
