import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import { useEffect } from "react";
import { Form } from "antd";
import Storage from "../../../utils/storage";
import { useTranslation } from "../../../i18n";
import GenericForm from "../../../components/GenericForm";
import { fetchShopsAction } from "../../../store/app/shop/shopActions";
import { addAction } from "../../../store/app/appActions";
import { addProductItemService } from "../../../store/products/productItem/productItemService";
import { ProductState, productActions } from "../../../store/products";
import { ShopState } from "../../../store/shop";

const NewPriceList = () => {
  const appState: AppState = useSelector((state: any) => state.app);
  const productState: ProductState = useSelector((state: any) => state.product);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldValue("productName", productState?.product?.name);

    if (!shopState.fetchedShops) {
      dispatch(
        fetchShopsAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }
  }, []);

  const additionalFields = productState?.product?.variants?.map(
    (variant: any) => {
      let options = variant.value.map((value: any) => {
        return {
          label: value,
          value,
        };
      });
      return {
        name: variant?.label,
        label: variant?.label,
        type: "select",
        selectLabel: "value",
        selectValue: "value",
        options,
      };
    }
  );

  let newItemFormElements = [
    {
      name: "productName",
      label: "Product",
      type: "text",
      disabled: true,
      rules: [{ required: true, message: "Product Name is required" }],
    },
    {
      name: "name",
      label: "Item Name",
      type: "text",
      rules: [{ required: true, message: "Product Name is required" }],
    },
    {
      name: "buyingPrice",
      label: "Buying Price",
      type: "number",
    },
    {
      name: "price",
      label: "Selling Price",
      type: "number",
    },
  ];

  newItemFormElements.push(...additionalFields);

  // ...additionalFields,
  const submit = (values: any) => {
    // "product": "64868bcbf78d07496b9eef0c",
    // "specification": [
    //     [
    //         "Color",
    //         "Green"
    //     ],
    //     [
    //         "Manufacturer",
    //         "Ameki"
    //     ]
    // ],
    // "image": "https://png.monster/wp-content/uploads/2022/09/png.monster-209.png",
    // "prices": [
    //     {
    //         "value": 150000,
    //         "shop": "644293a789dd516819854077"
    //     }
    // ]
    let data: any = {};
    let specification = [];
    data.product = productState?.product?._id;
    data.prices = [
      {
        value: values.price,
        shop: shopState.shop?._id,
      },
    ];
    data.buyingPrices = [
      {
        value: values.buyingPrice,
        shop: shopState.shop?._id,
      },
    ];
    data.name = values.name;
    delete values.productName;
    delete values.price;
    delete values.shop;
    delete values.productName;

    for (let key in values) {
      specification.push([key, values[key]]);
    }
    data.specification = specification;
    data.company = Storage.getUser()?.company?._id;

    dispatch(
      addAction(
        addProductItemService,
        data,
        token,
        () => {},
        () => dispatch(productActions.setFetchedProductItems(false))
      )
    );
  };

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => dispatch(appActions.closeForm())}
      form={form}
      styles="horizontal"
      title="New Product Item"
      formElements={newItemFormElements}
      onSubmit={submit}
    />
  );
};

export default NewPriceList;
