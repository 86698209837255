import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { primaryColor } from "../assets/colors/colors";

interface CustomLoadingButtonProps {
  loading: boolean;
  text: string;
}

const CustomLoadingButton = (props: CustomLoadingButtonProps) => {
  return (
    <LoadingButton
      variant="contained"
      className="w-auto"
      type="submit"
      loading={props.loading}
      loadingPosition="center"
      loadingIndicator={
        <CircularProgress style={{ color: "white" }} size={16} />
      }
      style={{
        marginTop: "0.8rem",
        backgroundColor: "#4084d8",
      }}
    >
      {props.text}
    </LoadingButton>
  );
};

export default CustomLoadingButton;
