import { Grid, Typography } from "@mui/material";
import styles from "../index.module.scss";

export interface UserCardValuesProps {
  label: string;
  value: string;
}

const UserCardValues = (props: UserCardValuesProps) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography className={styles.userCardLabel}>{props.label} :</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography className="whitespace-nowrap truncate">{props.value}</Typography>
      </Grid>
    </Grid>
  );
};

export default UserCardValues;
