import { createSlice } from "@reduxjs/toolkit";

export interface DashboardState {
  fetchedTopSelling: boolean;
  fetchedStats: boolean;
  fetchedTotalSales: boolean;
  fetchedStockValue: boolean;
  fetchedTotalDebt: boolean;
  fetchedTotalCredit: boolean;
  fetchingTopSelling: boolean;
  fetchingStats: boolean;
  fetchingTotalSales: boolean;
  fetchingStockValue: boolean;
  fetchingTotalDebt: boolean;
  fetchingTotalCredit: boolean;
  topSellingProducts: [];
  stats: {
    suppliers: number;
    customers: number;
    products: number;
    users: number;
  };
  stockValue: number;
  totalSales: {
    totalSales: number;
    totalRevenue: number;
  };
  totalDebt: number;
  totalCredit: number;
}

const initialState: DashboardState = {
  fetchedTopSelling: false,
  fetchedStats: false,
  fetchedTotalSales: false,
  fetchedStockValue: false,
  fetchedTotalDebt: false,
  fetchedTotalCredit: false,
  fetchingTopSelling: false,
  fetchingStats: false,
  fetchingTotalSales: false,
  fetchingStockValue: false,
  fetchingTotalDebt: false,
  fetchingTotalCredit: false,
  topSellingProducts: [],
  stats: {
    suppliers: 0,
    customers: 0,
    products: 0,
    users: 0,
  },
  stockValue: 0,
  totalSales: {
    totalSales: 0,
    totalRevenue: 0,
  },
  totalDebt: 0,
  totalCredit: 0,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFetchedTopSelling(state, action) {
      state.fetchedTopSelling = action.payload;
    },
    setFetchingTopSelling(state, action) {
      state.fetchingTopSelling = action.payload;
    },
    setFetchedStats(state, action) {
      state.fetchedStats = action.payload;
    },
    setFetchingStats(state, action) {
      state.fetchingStats = action.payload;
    },
    setFetchingTotalCredit(state, action) {
      state.fetchingTotalCredit = action.payload;
    },
    setFetchingTotalDebt(state, action) {
      state.fetchingTotalDebt = action.payload;
    },
    setFetchedTotalCredit(state, action) {
      state.fetchedTotalCredit = action.payload;
    },
    setFetchedTotalDebt(state, action) {
      state.fetchedTotalDebt = action.payload;
    },
    setFetchedTotalSales(state, action) {
      state.fetchedTotalSales = action.payload;
    },
    setFetchingTotalSales(state, action) {
      state.fetchingTotalSales = action.payload;
    },
    setFetchedStockValue(state, action) {
      state.fetchedStockValue = action.payload;
    },
    setFetchingStockValue(state, action) {
      state.fetchingStockValue = action.payload;
    },
    setTopSellingProducts(state, action) {
      state.topSellingProducts = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setTotalSales(state, action) {
      state.totalSales = action.payload;
    },
    setStockValue(state, action) {
      state.stockValue = action.payload;
    },
    setTotalDebt(state, action) {
      state.totalDebt = action.payload;
    },
    setTotalCredit(state, action) {
      state.totalCredit = action.payload;
    },
    resetDashboardState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
