import { Divider, Grid, Skeleton } from "@mui/material";
import Layout from "../../components/Layout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DiamondIcon from "@mui/icons-material/Diamond";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { Typography } from "antd";
import LineChart from "../../components/charts/LineChart";
import { useEffect } from "react";
import { DashboardState } from "../../store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardStatsAction,
  getStockValueAction,
  getTopSellingProductsAction,
  getTotalCreditAction,
  getTotalDebtAction,
  getTotalSalesAction,
} from "../../store/dashboard/dashboardActions";
import Storage from "../../utils/storage";
import { numberWithCommas, renderMoney } from "../../utils/functions";
import { AppState } from "../../store/app";
import { getShopsAction } from "../../store/app/shop/shopActions";
import { ShopState } from "../../store/shop";
import { ProductState } from "../../store/products";
import {
  getProductItemsAction,
  getProductsAction,
} from "../../store/products/product/productActions";
import { useTranslation } from "../../i18n";

const Dashboard = () => {
  const dashboardState: DashboardState = useSelector(
    (state: any) => state.dashboard
  );
  const productState: ProductState = useSelector((state: any) => state.product);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const { t } = useTranslation();

  let chartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: t("profit"),
        data: [40, 120, 130, 60, 30, 40, 70, 110, 120, 150, 90, 60],
        backgroundColor: "#5de89c",
        borderColor: "orange",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  useEffect(() => {
    if (!shopState.fetchedShops) {
      dispatch(
        getShopsAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }

    if (!dashboardState.fetchedStats) {
      dispatch(
        getDashboardStatsAction(
          `?company=${Storage.getUser()?.company?._id}`,
          token
        )
      );
    }

    if (!dashboardState.fetchedStockValue) {
      dispatch(
        getStockValueAction(
          `?company=${
            Storage.getUser()?.company?._id
          }&branch=64f3fd8dd9cb7a35e31aa45a`,
          token
        )
      );
    }

    if (!dashboardState.fetchedTopSelling) {
      dispatch(
        getTopSellingProductsAction(
          `?company=${Storage.getUser()?.company?._id}`,
          token
        )
      );
    }

    if (!dashboardState.fetchedTotalSales) {
      dispatch(
        getTotalSalesAction(
          `?company=${Storage.getUser()?.company?._id}`,
          token
        )
      );
    }

    if (!dashboardState.fetchedTotalCredit) {
      dispatch(
        getTotalCreditAction(
          `?company=${Storage.getUser()?.company?._id}`,
          token
        )
      );
    }

    if (!dashboardState.fetchedTotalDebt) {
      dispatch(
        getTotalDebtAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }

    if (!productState.fetchedProducts) {
      dispatch(
        getProductsAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }

    if (!productState.fetchedProductItems) {
      dispatch(
        getProductItemsAction(
          `?company=${Storage.getUser()?.company?._id}`,
          token
        )
      );
    }
  }, []);

  console.log("dashboard rendering");

  return (
    <Layout>
      <div className="h-[92vh] overflow-y-scroll me-[-1rem] pe-3 pb-8">
        <div className="flex items-center mt-8 p-4  rounded-2xl text-gray-300 bg-[#252733] w-[24%] mb-6">
          <AttachMoneyIcon
            style={{ height: "2rem", width: "2rem" }}
            className="text-teal-500 me-4"
          />
          <div>
            <div>{t("totalSales")}</div>
            <div className="font-semibold text-xl">
              {renderMoney(dashboardState?.totalSales?.totalSales)}
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className="bg-white p-4 rounded-2xl shadow-lg">
              <div className="flex items-center">
                <DiamondIcon
                  style={{ height: "2rem", width: "2rem" }}
                  className="text-blue-500 me-4"
                />
                <div>
                  <div>{t("stockValue")}</div>
                  {!dashboardState.fetchingStockValue && (
                    <div className="font-semibold text-xl">
                      {renderMoney(dashboardState?.stockValue)}
                    </div>
                  )}
                  {dashboardState.fetchingStockValue && (
                    <Skeleton
                      style={{
                        height: "2rem",
                        width: "10rem",
                        marginTop: "0rem",
                        marginBottom: "0rem",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="bg-white p-4 rounded-2xl shadow-lg">
              <div className="flex items-center">
                <AutoAwesomeMosaicIcon
                  style={{ height: "2rem", width: "2rem" }}
                  className="text-red-500 me-4"
                />
                <div>
                  <div>{t("debts")}</div>
                  {!dashboardState.fetchingTotalDebt && (
                    <div className="font-semibold text-xl">
                      {renderMoney(dashboardState?.totalDebt)}
                    </div>
                  )}
                  {dashboardState.fetchingTotalDebt && (
                    <Skeleton
                      style={{
                        height: "2rem",
                        width: "10rem",
                        marginTop: "0rem",
                        marginBottom: "0rem",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="bg-white p-4 rounded-2xl shadow-lg">
              <div className="flex items-center">
                <AccountBalanceWalletIcon
                  style={{ height: "2rem", width: "2rem" }}
                  className="text-green-500 me-4"
                />
                <div>
                  <div>{t("credits")}</div>
                  {!dashboardState.fetchingTotalCredit && (
                    <div className="font-semibold text-xl">
                      {renderMoney(dashboardState?.totalCredit)}
                    </div>
                  )}
                  {dashboardState.fetchingTotalCredit && (
                    <Skeleton
                      style={{
                        height: "2rem",
                        width: "10rem",
                        marginTop: "0rem",
                        marginBottom: "0rem",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="bg-white p-4 rounded-2xl shadow-lg">
              <div className="flex items-center">
                <AttachMoneyIcon
                  style={{ height: "2rem", width: "2rem" }}
                  className="text-teal-500 me-1"
                />
                <div>
                  <div>{t("totalIncome")}</div>
                  {!dashboardState.fetchingTotalSales && (
                    <div className="font-semibold text-xl">
                      {renderMoney(dashboardState?.totalSales?.totalRevenue)}
                    </div>
                  )}
                  {dashboardState.fetchingStockValue && (
                    <Skeleton
                      style={{
                        height: "2rem",
                        width: "10rem",
                        marginTop: "0rem",
                        marginBottom: "0rem",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="flex my-8">
              <div className="w-full pb-8 bg-white me-4 rounded-2xl">
                <div>
                  <div style={{ width: "38rem" }}>
                    {chartData && <LineChart chartData={chartData} />}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="my-8 w-full h-64 bg-white rounded-2xl">
              {dashboardState.fetchingStats && (
                <div className="pt-4">
                  <div className="h-24 mx-4 w-40 rounded-lg">
                    <Skeleton
                      style={{
                        height: "100%",
                        width: "100%",
                        transform: "none",
                      }}
                    />
                  </div>
                </div>
              )}
              {!dashboardState.fetchingStats && (
                <div className="flex items-center justify-center flex-col">
                  <div className="flex mt-4">
                    <div className="h-24 w-40 bg-gray-200 rounded-lg m-2 flex justify-center items-center flex-col">
                      <div className="font-semibold text-3xl">
                        {dashboardState?.stats?.customers}
                      </div>
                      <div className="text-gray-400 font-bold text-sm">
                        {t("customers")}
                      </div>
                    </div>
                    <div className="h-24 w-40 bg-gray-200 rounded-lg m-2 flex justify-center items-center flex-col">
                      <div className="font-semibold text-3xl">
                        {dashboardState?.stats?.suppliers}
                      </div>
                      <div className="text-gray-400 font-bold text-sm">
                        {t("suppliers")}
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="h-24 w-40 bg-gray-200 rounded-lg m-2 flex justify-center items-center flex-col">
                      <div className="font-semibold text-3xl">
                        {dashboardState?.stats?.products}
                      </div>
                      <div className="text-gray-400 font-bold text-sm">
                        {t("products")}
                      </div>
                    </div>
                    <div className="h-24 w-40 bg-gray-200 rounded-lg m-2 flex justify-center items-center flex-col">
                      <div className="font-semibold text-3xl">
                        {dashboardState?.stats?.users}
                      </div>
                      <div className="text-gray-400 font-bold text-sm">
                        {t("users")}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="bg-white rounded-md py-6 px-2">
              <Typography className="text-xl font-bold mb-6">
                {t("topSellingProducts")}
              </Typography>
              <div className="flex my-4 font-semibold text-sm">
                <div className="basis-1/12 text-center">Nbr</div>
                <div className="basis-7/12 text-center">{t("amount")}</div>
                <div className="basis-2/12 text-center">Qty</div>
                <div className="basis-2/12 text-center">{t("amount")}</div>
              </div>
              <hr />
              {dashboardState?.topSellingProducts?.map(
                (product: any, index: number) => (
                  <div
                    key={product._id + index}
                    className="flex text-sm text-center my-2 border-b pb-2"
                  >
                    <div className="basis-1/12 text-center">{index + 1}</div>
                    <div className="basis-7/12 text-start ms-2 whitespace-nowrap truncate">
                      {product?.name}
                    </div>
                    <div className="basis-2/12 text-center">
                      {product?.totalQuantitySold}
                    </div>
                    <div className="basis-2/12 text-center">
                      12,0000
                      {/* {numberWithCommas(
                        product?.totalQuantitySold * product?.prices[0]?.value
                      )} */}
                    </div>
                  </div>
                )
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="bg-white p-4"></div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Dashboard;
