import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getProductPricesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/productprice${query}`, token);
};

export const updateProductPriceService = async (
  productPriceId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/productprice/one/${productPriceId}`,
    data,
    token
  );
};

export const addProductPriceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/productprice`, data, token);
};

export const deleteProductPriceService = async (
  productPriceId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/productprice/one/${productPriceId}`,
    token
  );
};
