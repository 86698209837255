import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getProductsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/product${query}`, token);
};

export const getProductUnitsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/productunit${query}`, token);
};

export const setProductImageService = async (
  productId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/product/upload-image/${productId}`,
    data,
    token
  );
};

export const searchProductService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/product/search${query}`, token);
};

export const updateProductService = async (
  productId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/product/one/${productId}`,
    data,
    token
  );
};

export const addProductService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product`, data, token);
};

export const deleteProductService = async (
  productId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/product/one/${productId}`,
    token
  );
};
