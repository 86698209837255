import { createSlice } from "@reduxjs/toolkit";
import { ProductItem } from "../../interfaces/product/productItem";
import { Product } from "../../interfaces/product/product";
import { Stock } from "../../interfaces/stock";
import { ProductUnit } from "../../interfaces/product/productUnit";
import { ProductPrice } from "../../interfaces/product/productPrice";

export interface ProductState {
  productItem: any;
  productUnits: ProductUnit[];
  stockItem: any;
  productItems: ProductItem[];
  fetchedProductItems: boolean;
  fetchingProductItems: boolean;
  productPrices: ProductPrice[];
  fetchedProductPrices: boolean;
  fetchingProductPrices: boolean;
  products: Product[];
  stockProducts: Product[];
  productsCount: number;
  stock: Stock[];
  product: any;
  history: {
    purchases: [];
    sales: [];
  };
  fetchedProducts: boolean;
  fetchingProducts: boolean;
  fetchingHistory: boolean;
}

const initialState: ProductState = {
  productItem: {},
  stockItem: {},
  productUnits: [],
  productItems: [],
  fetchedProductItems: false,
  fetchingProductItems: false,
  productPrices: [],
  fetchedProductPrices: false,
  fetchingProductPrices: false,
  products: [],
  stockProducts: [],
  productsCount: 0,
  stock: [],
  product: {},
  history: {
    purchases: [],
    sales: [],
  },
  fetchedProducts: false,
  fetchingProducts: false,
  fetchingHistory: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setStockProducts(state, action) {
      state.stockProducts = action.payload;
    },
    setProductsCount(state, action) {
      state.productsCount = action.payload;
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    setFetchedProducts(state, action) {
      state.fetchedProducts = action.payload;
    },
    setFetchingProducts(state, action) {
      state.fetchingProducts = action.payload;
    },
    setProductItems(state, action) {
      state.productItems = action.payload;
    },
    setProductUnits(state, action) {
      state.productUnits = action.payload;
    },
    setFetchedProductItems(state, action) {
      state.fetchedProductItems = action.payload;
    },
    setFetchingProductItems(state, action) {
      state.fetchingProductItems = action.payload;
    },
    setProductPrices(state, action) {
      state.productPrices = action.payload;
    },
    setFetchedProductPrices(state, action) {
      state.fetchedProductPrices = action.payload;
    },
    setFetchingProductPrices(state, action) {
      state.fetchingProductPrices = action.payload;
    },
    setProductItem(state, action) {
      state.productItem = action.payload;
    },
    setStockItem(state, action) {
      state.stockItem = action.payload;
    },
    setHistory(state, action) {
      state.history = action.payload;
    },
    setStock(state, action) {
      state.stock = action.payload;
    },
    setFetchingHistory(state, action) {
      state.fetchingHistory = action.payload;
    },
    resetProductState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice.reducer;
