import { Button, Menu, MenuItem } from "@mui/material";
import styles from "../index.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AppState, appActions } from "../../store/app";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import Storage from "../../utils/storage";
import illustration from "../../assets/images/illu_sales.png";
import {
  calculateTotalPrice,
  calculateTotalPriceNumber,
  renderMoney,
} from "../../utils/functions";
import { AuthState } from "../../store/auth";
import NewSale from "./NewSale";
import { SalesState, salesActions } from "../../store/sales";
import { Sale } from "../../interfaces/sales/sale";
import { SalesItem } from "../../interfaces/sales/salesItem";
import { getSalesAction } from "../../store/sales/salesActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ConfirmModal from "../../components/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { deleteAction } from "../../store/app/appActions";
import FilterListIcon from "@mui/icons-material/FilterList";
import { deleteSalesService } from "../../store/sales/salesService";
import { increaseCustomerCreditService } from "../../store/app/customer/customerService";
import ListLoadingItem from "../../components/ListLoadingItem";
import NoData from "../../components/NoData";
import { ShopState } from "../../store/shop";

interface SalesItemRowProps {
  index: number;
  name: string;
  unitPrice: string;
  quantity: number;
  totalPrice: string;
}

const SalesItemRow = (props: SalesItemRowProps) => {
  return (
    <div
      style={{ background: `${props.index % 2 == 0 ? "#f5faff" : "white"}` }}
      className="flex py-2 ps-2"
    >
      <div className="basis-1/12">{props.index}</div>
      <div className="basis-4/12 text-center whitespace-nowrap truncate">
        {props.name}
      </div>
      <div className="basis-2/12 text-center">{props.unitPrice}</div>
      <div className="basis-2/12 text-center">{props.quantity}</div>
      <div className="basis-3/12 text-center">{props.totalPrice}</div>
    </div>
  );
};

const SalesPage = () => {
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const [addSale, setAddSale] = useState(false);
  const [sale, setSale] = useState<Sale | null>();
  const appState: AppState = useSelector((state: any) => state.app);
  const authState: AuthState = useSelector((state: any) => state.auth);
  const [query, setQuery] = useState(
    `?company=${Storage.getUser()?.company?._id}`
  );
  const salesState: SalesState = useSelector((state: any) => state.sales);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

  useEffect(() => {
    if (!salesState.fetchedSales) {
      dispatch(getSalesAction(query, token));
    }
  }, [dispatch, salesState.fetchedSales, token]);

  useEffect(() => {
    if (shopState?.shop?._id) {
      setQuery(`?shop=${shopState?.shop?._id}`);
      dispatch(salesActions.setFetchedSales(false));
    } else {
      setQuery(`?company=${Storage.getUser()?.company?._id}`);
      dispatch(salesActions.setFetchedSales(false));
    }
  }, [shopState.shop]);

  const deleteSaleHandler = async () => {
    if (sale?._id) {
      if (sale?.isCredit) {
        await increaseCustomerCreditService(
          sale?.customer._id,
          {
            amount:
              sale?.amountPaid[0].value - calculateTotalPriceNumber(sale?.list),
          },
          token
        );
      }

      dispatch(
        deleteAction(
          deleteSalesService,
          sale?._id,
          token,
          "Sales",
          () => {},
          () => {
            setSale(null);
            dispatch(salesActions.setFetchedSales(false));
          }
        )
      );
    }
  };

  return (
    <>
      {addSale && <NewSale close={() => setAddSale(false)} />}
      {!addSale && (
        <>
          <div className="flex justify-between my-4">
            <Typography className="font-bold text-xl">Sales</Typography>
            <Button
              className={styles.primaryButton}
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              onClick={() => setAddSale(true)}
            >
              Add Sale
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="bg-white py-4">
                <div className="flex items-center">
                  <div className="flex border rounded-md ms-4 mb-3 p-2 basis-5/6">
                    <SearchIcon className="me-3" />
                    <input
                      placeholder="Search By Customer"
                      className="w-full outline-none"
                      type="text"
                    />
                  </div>
                  <FilterListIcon className="basis-1/6 cursor-pointer" />
                </div>
                <hr />
                {!appState.isFetching && (
                  <div style={{ height: "70vh", overflowY: "scroll" }}>
                    {salesState.sales.length > 0 &&
                      salesState.sales.map((currentSale: Sale) => (
                        <div
                          key={currentSale._id}
                          style={{
                            backgroundColor:
                              currentSale._id === sale?._id
                                ? "#EBF6FF"
                                : "white",
                          }}
                          className="p-2 m-4 rounded-md cursor-pointer shadow-md"
                          onClick={() => setSale(currentSale)}
                        >
                          <div className="flex justify-between">
                            <div>
                              {currentSale?.customer?.name ?? "Customer"}
                            </div>
                            <ArrowForwardIosIcon style={{ height: "1rem" }} />
                          </div>
                          <div>{currentSale.list.length} Items</div>
                          <div className="flex justify-between">
                            <div className="font-bold">
                              {calculateTotalPrice(currentSale.list)} RWF
                            </div>
                            <div>
                              {currentSale.date
                                .substring(0, 10)
                                .replaceAll("-", ".")}
                            </div>
                          </div>
                        </div>
                      ))}
                    {salesState.sales?.length === 0 && <NoData />}
                  </div>
                )}
                {appState.isFetching && (
                  <div style={{ height: "29rem" }}>
                    <ListLoadingItem />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                className="h-[81vh] bg-white overflow-y-scroll"
              >
                {sale?.list && (
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                      <div
                        className="bg-gray-100 rounded-lg p-2 cursor-pointer"
                        onClick={() => setSale(null)}
                      >
                        <ArrowBackIosNewIcon />
                      </div>
                      <div className="bg-gray-100 rounded-lg p-2 cursor-pointer">
                        <MoreVertIcon
                          onClick={(event: any) => {
                            setAnchorEl(event.target);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <div className="font-semibold text-xl mb-2">
                          {authState?.user?.company?.name}
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">Address:</div>
                          <div>{authState?.user?.company?.address}</div>
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">Contact :</div>
                          <div>{authState?.user?.company?.phone}</div>
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">Tin Number :</div>
                          <div>{authState?.user?.company?.tinNumber}</div>
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold text-xl mb-2">Sales</div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">Time:</div>
                          <div>{new Date(sale.date).toLocaleTimeString()}</div>
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">Date:</div>
                          <div>{new Date(sale.date).toLocaleDateString()}</div>
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">P.O Number:</div>
                          <div>{sale.salesID}</div>
                        </div>
                        <div className="text-sm flex">
                          <div className="font-semibold me-2">
                            Customer Name:
                          </div>
                          <div>{sale?.customer?.name ?? "Supplier"}</div>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-8 bg-slate-100 py-2 ps-2">
                      <div className="basis-1/12">SL</div>
                      <div className="basis-4/12 text-center">Item Name</div>
                      <div className="basis-2/12 text-center">Unit Price</div>
                      <div className="basis-2/12 text-center">Quantity</div>
                      <div className="basis-3/12 text-center">Total Price</div>
                    </div>
                    {sale?.list?.map((salesItem: SalesItem, index: number) => (
                      <SalesItemRow
                        index={index + 1}
                        name={salesItem.productItem.name}
                        unitPrice={renderMoney(salesItem.unitPrice)}
                        quantity={salesItem.quantity}
                        totalPrice={renderMoney(
                          salesItem.quantity * salesItem.unitPrice
                        )}
                      />
                    ))}

                    <div className="flex justify-between">
                      <div className="">
                        <div className="font-semibold mt-10 mb-4">
                          Payment Info:
                        </div>
                        <div className="font-semibold">Payment Method:</div>
                        <div className="font-semibold">A.C Name:</div>
                      </div>
                      <div className="mt-10 w-4/12">
                        <div className="flex justify-between">
                          <div className="font-semibold">SubTotal:</div>
                          <div>{calculateTotalPrice(sale.list)}</div>
                        </div>
                        <div className="flex justify-between">
                          <div className="font-semibold">Tax</div>
                          <div>0</div>
                        </div>
                        <div className="flex font-bold text-xl justify-between text-primaryColor mt-4">
                          <div>Total:</div>
                          <div>{calculateTotalPrice(sale.list)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="my-8 font-semibold">Approved by:</div>
                    <div className="flex justify-between border-t-2 border-gray-200 pt-2">
                      <div>Authorized Signature</div>
                      <div className="me-12">Date</div>
                    </div>
                  </div>
                )}
                {!sale?.list && <div className="h-[81vh] bg-white w-full flex items-center justify-center">
                  <img
                    className="w-[16vw] h-[35vh]"
                    src={illustration}
                    alt="Purchase"
                  />
                </div>}
              </div>
              {Boolean(anchorEl) && (
                <Menu
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                >
                  <MenuItem>
                    <AutorenewIcon />
                    Update
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(appActions.setIsDeleting(true));
                      setAnchorEl(null);
                    }}
                  >
                    <DeleteIcon />
                    Delete
                  </MenuItem>
                </Menu>
              )}
              {appState.isDeleting && (
                <ConfirmModal
                  onConfirm={() => deleteSaleHandler()}
                  content={`Are you sure you want to delete ${sale?.salesID} Sales`}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SalesPage;
