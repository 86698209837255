import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import appReducer from "./app";
import salesReducer from "./sales";
import purchasesReducer from "./purchase";
import dashboardReducer from "./dashboard";
import productReducer from "./products";
import shopReducer from "./shop";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  sales: salesReducer,
  purchase: purchasesReducer,
  dashboard: dashboardReducer,
  product: productReducer,
  shop: shopReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["shop"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;

export const persistor = persistStore(store);
