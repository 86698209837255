import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tab,
  TablePagination,
  Tabs,
} from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { AppState, appActions } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../i18n";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Storage from "../../../utils/storage";
import SellIcon from "@mui/icons-material/Sell";
import { Typography } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import LoadingContainer from "../../../components/LoadingContainer";
import { Link, useParams } from "react-router-dom";
import NewPriceList from "./NewProductItem";
import { getProductItemHistoryAction } from "../../../store/products/productItem/productItemActions";
import {
  ProductItem,
  ProductItemRow,
} from "../../../interfaces/product/productItem";
import { initials, renderMoney } from "../../../utils/functions";
import UpdatePriceList from "./UpdatePriceList";
import { ProductState, productActions } from "../../../store/products";
import { PurchaseItem } from "../../../interfaces/purchaseItem";
import { SalesItem } from "../../../interfaces/sales/salesItem";
import { Stock } from "../../../interfaces/stock";
import { deleteRed2, primaryGreen } from "../../../assets/colors/colors";

const ProductItemDetails = () => {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [priceList, setPriceList] = useState<ProductItem>();
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const appState: AppState = useSelector((state: any) => state.app);
  const productState: ProductState = useSelector((state: any) => state.product);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const exportExcelHandler = () => {};
  const [activeTab, setActiveTab] = useState(0);
  const [history, setHistory] = useState<any>([]);
  let stockBalance = 0;

  useEffect(() => {
    dispatch(
      getProductItemHistoryAction(
        productState?.stockItem?.productItem?._id,
        token
      )
    );
  }, []);

  console.log("fetchinggggg ", productState.fetchingHistory);
  useEffect(() => {
    if (!productState.fetchingHistory) {
      setHistory(getHistory());
    }
  }, [productState.fetchingHistory]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(productActions.setFetchedProductItems(false));
  };

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(productActions.setFetchedProductItems(false));
  };

  const createData = (priceList: ProductItem): ProductItemRow => {
    return {
      date: priceList?.createdAt.substring(0, 10),
      description: priceList?.name,
      price: renderMoney(priceList.prices[0]?.value),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setPriceList(priceList);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const compareDates = (a: any, b: any) => {
    return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
  };

  const getHistory = () => {
    const sales = productState.history?.sales?.map((salesItem: SalesItem) => {
      return {
        _id: salesItem._id,
        customer: salesItem?.sales?.customer?.name,
        quantity: salesItem?.quantity,
        type: "sales",
        date: salesItem?.sales?.date,
      };
    });

    const purchases = productState.history?.purchases?.map(
      (purchaseItem: PurchaseItem) => {
        return {
          _id: purchaseItem._id,
          supplier: purchaseItem?.purchase?.supplier?.name,
          quantity: purchaseItem?.quantity,
          type: "purchase",
          date: purchaseItem?.purchase?.date,
        };
      }
    );

    let history = [...purchases, ...sales].sort(compareDates);
    // let history = [purchases].sort(compareDates);
    return history;
  };

  return (
    <Layout>
      <Box className={styles.searchComponent}>
        <div className="flex items-center">
          <Link to={`/products/${productState.product._id}`}>
            <ArrowCircleLeftOutlinedIcon className="cursor-pointer" />
          </Link>
          <Typography className="font-bold text-xl ms-2">
            {`Products / ${productState.product.name} / ${productState?.stockItem?.productItem?.name}`}
          </Typography>
        </div>
        <Button
          className={styles.primaryButton}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: "add" }));
          }}
        >
          Add Product Item
        </Button>
      </Box>
      <div className="flex justify-between">
        <Typography>Filter</Typography>
        <div className="flex items-center">
          {/* <SearchComponent setSearchText={setSearchText} /> */}
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
        </div>
      </div>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div
                  style={{ height: "35.2rem" }}
                  className="bg-white overflow-y-scroll rounded-lg p-4 mt-2 overflow-hidden"
                >
                  <div className="flex border rounded-lg mx-1 p-1">
                    <SearchIcon className="me-3" />
                    <input
                      placeholder="Search Item"
                      className="w-full outline-none"
                      type="text"
                    />
                  </div>
                  {productState?.stock?.length > 0 &&
                    productState?.stock?.map((stockItem: Stock) => (
                      <div
                        key={stockItem?.productItem._id}
                        className="cursor-pointer h-20 flex items-center px-4 py-2 border-b border-gray-200"
                        onClick={() => {
                          // dispatch(appActions.setProduct(productItem));
                          // dispatch(appActions.setFetchedProductItems(false));
                        }}
                      >
                        {" "}
                        {!productState?.product?.image?.url && (
                          <div className="bg-gray-200 rounded-md h-12 w-12 flex justify-center items-center me-2">
                            <Typography className="whitespace-nowrap font-semibold text-2xl text-gray-500">
                              {initials(stockItem?.productItem.name)}
                            </Typography>
                          </div>
                        )}
                        {productState?.product?.image?.url && (
                          <img
                            className={styles.productCardImage}
                            src={productState?.product?.image?.url}
                            alt="pff"
                          />
                        )}
                        <div>{stockItem?.productItem.name}</div>
                      </div>
                      // <ProductCard key={product._id} product={product} />
                    ))}
                </div>
              </Grid>
              <Grid item xs={9}>
                <div
                  style={{ height: "35.1rem", overflowY: "scroll" }}
                  className="mt-1 bg-white"
                >
                  <div className="flex justify-center">
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      scrollButtons={false}
                      aria-label="scrollable prevent tabs example"
                    >
                      <Tab label="Movement" />
                      <Tab label="Purchases" />
                      <Tab label="Sales" />
                      <Tab label="Specs" />
                    </Tabs>
                  </div>
                  <div className="p-8">
                    {activeTab === 0 && (
                      <>
                        {!productState.fetchingHistory && (
                          <div className="h-[60vh] w-[70%] border mx-auto pt-4 rounded-lg">
                            <div className="flex mb-2 font-semibold">
                              <div className="basis-1/12 text-center">Nbr</div>
                              <div className="basis-5/12 text-center">Date</div>
                              <div className="basis-3/12 text-center">
                                Quantity
                              </div>
                              <div className="basis-3/12 text-center">
                                Stock Balance
                              </div>
                            </div>
                            {history?.length > 0 &&
                              history?.map((history: any, index: number) => {
                                stockBalance += history?.quantity;
                                return (
                                  <div
                                    key={history?._id}
                                    style={{
                                      background: `${
                                        index % 2 === 0 ? "#f5faff" : "white"
                                      }`,
                                    }}
                                    className="flex py-1 border-b-2 border-gray-100"
                                  >
                                    <div className="basis-1/12 text-center">
                                      {index + 1}
                                    </div>
                                    <div className="basis-5/12 text-center">
                                      {history?.date?.substring(0, 10)}
                                    </div>
                                    <div className="basis-3/12 text-center flex justify-center">
                                      {history?.type === "purchase" ? (
                                        <ArrowUpwardIcon
                                          style={{ color: primaryGreen }}
                                        />
                                      ) : (
                                        <ArrowDownwardIcon
                                          style={{ color: deleteRed2 }}
                                        />
                                      )}
                                      <div>{history?.quantity}</div>
                                    </div>
                                    <div className="basis-3/12 text-center">
                                      {stockBalance}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                        {productState.fetchingHistory && (
                          <div className="h-[60vh] w-[70%] border mx-auto pt-4 rounded-lg flex justify-center items-center">
                            <CircularProgress />
                          </div>
                        )}
                      </>
                    )}
                    {activeTab === 1 && (
                      <>
                        {productState.history?.purchases?.length > 0 && (
                          <div
                            style={{ height: "24rem", overflowY: "scroll" }}
                            className="border w-[70%] mx-auto pt-4 rounded-lg"
                          >
                            <div className="flex mb-2 font-semibold">
                              <div className="basis-1/12 text-center">Nbr</div>
                              <div className="basis-3/12 text-center">Date</div>
                              <div className="basis-5/12 text-center">
                                Supplier
                              </div>
                              <div className="basis-3/12 text-center">
                                Quantity
                              </div>
                            </div>
                            {productState.history?.purchases?.map(
                              (purchase: PurchaseItem, index: number) => (
                                <div
                                  key={purchase._id}
                                  style={{
                                    background: `${
                                      index % 2 === 0 ? "#f5faff" : "white"
                                    }`,
                                  }}
                                  className="flex py-1 border-b-2 border-gray-100"
                                >
                                  <div className="basis-1/12 text-center">
                                    {index + 1}
                                  </div>
                                  <div className="basis-3/12 text-center">
                                    {purchase?.purchase?.date?.substring(0, 10)}
                                  </div>
                                  <div className="basis-5/12 text-center">
                                    {purchase?.purchase?.supplier?.name ??
                                      "SUPPLIER"}
                                  </div>
                                  <div className="basis-3/12 text-center">
                                    {purchase.quantity}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                        <div className="w-[70%] mx-auto">
                          <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handlePageChange}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleRowsPerPageChange}
                          />
                        </div>
                      </>
                    )}
                    {activeTab === 2 && (
                      <>
                        {productState.history?.sales?.length > 0 && (
                          <div className="border w-[70%] mx-auto pt-4 rounded-lg">
                            <div className="flex mb-2 font-semibold">
                              <div className="basis-1/12 text-center">Nbr</div>
                              <div className="basis-3/12 text-center">Date</div>
                              <div className="basis-5/12 text-center">
                                Customer
                              </div>
                              <div className="basis-3/12 text-center">
                                Quantity
                              </div>
                            </div>
                            {productState.history?.sales?.map(
                              (sale: SalesItem, index: number) => (
                                <div
                                  key={sale._id}
                                  style={{
                                    background: `${
                                      index % 2 == 0 ? "#f5faff" : "white"
                                    }`,
                                  }}
                                  className="flex py-1 border-b-2 border-gray-100"
                                >
                                  <div className="basis-1/12 text-center">
                                    {index + 1}
                                  </div>
                                  <div className="basis-3/12 text-center">
                                    {sale?.sales?.date?.substring(0, 10)}
                                  </div>
                                  <div className="basis-5/12 text-center">
                                    {sale?.sales?.customer?.name ?? "CUSTOMER"}
                                  </div>
                                  <div className="basis-3/12 text-center">
                                    {sale.quantity}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {activeTab === 3 && (
                      <div className="w-[70%] mx-auto">
                        {productState.product?.name}
                        <Typography className="font-bold text-2xl text-blue-500 mb-2">
                          {productState?.stockItem?.productItem?.name}
                        </Typography>
                        <hr className="mb-4" />
                        {productState.product?.variants.map((variant: any) => (
                          <div className="mt-1" key={variant?.label}>
                            <div>{variant?.label}</div>
                            <div className="flex mb-2">
                              {variant?.value?.map((value: any) => (
                                <div
                                  className="border rounded-md m-2 px-2 py-1"
                                  key={value}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                            <hr />
                          </div>
                        ))}
                        <div className="flex justify-between items-center">
                          <div className="my-4 flex items-end">
                            <Typography className="text-secondaryTextColor font-semibold">
                              Buying Price:
                            </Typography>
                            <Typography className=" font-bold ms-4 text-xl">
                              {renderMoney(
                                productState?.stockItem?.productItem
                                  ?.buyingPrices[0]?.value
                              )}
                            </Typography>
                          </div>
                          <div className="my-4 flex items-end">
                            <Typography className="text-secondaryTextColor font-semibold">
                              Selling Price:
                            </Typography>
                            <Typography className=" font-bold ms-4 text-xl">
                              {renderMoney(
                                productState?.stockItem?.productItem?.prices[0]
                                  ?.value
                              )}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-end">
                          <Typography className="text-secondaryTextColor font-semibold">
                            Current Stock:
                          </Typography>
                          <Typography className=" font-bold ms-12 text-xl">
                            {productState?.stockItem?.stock}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  {Boolean(anchorEl) && (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setUpdate(true);
                          setAnchorEl(null);
                        }}
                      >
                        <AutorenewIcon />
                        <Typography>Update</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => {}}>
                        <SellIcon />
                        <Typography>Update Price</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          dispatch(appActions.setIsDeleting(true));
                        }}
                      >
                        <DeleteIcon />
                        <Typography>Delete</Typography>
                      </MenuItem>
                    </Menu>
                  )}
                  {update && (
                    <UpdatePriceList
                      open={update}
                      productItem={priceList}
                      close={() => setUpdate(false)}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </>
        </>
      )}
      {appState.openForm.open && <NewPriceList />}
    </Layout>
  );
};

export default ProductItemDetails;
