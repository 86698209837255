import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import { useEffect } from "react";
import { Form } from "antd";
import Storage from "../../../utils/storage";
import GenericForm from "../../../components/GenericForm";
import { updateAction } from "../../../store/app/appActions";
import { updateProductItemService } from "../../../store/products/productItem/productItemService";
import { ProductItem } from "../../../interfaces/product/productItem";
import { ProductState, productActions } from "../../../store/products";
import { ShopState } from "../../../store/shop";

export interface UpdatePriceListProps {
  open: boolean;
  close: Function;
  productItem?: ProductItem;
}

const UpdatePriceList = (props: UpdatePriceListProps) => {
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const productState: ProductState = useSelector((state: any) => state.product);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("productName", productState?.product?.name);
    form.setFieldValue("name", props?.productItem?.name);
  }, []);

  const additionalFields = productState?.product?.variants?.map(
    (variant: any) => {
      let options = variant.value.map((value: any) => {
        return {
          label: value,
          value,
        };
      });
      return {
        name: variant?.label,
        label: variant?.label,
        type: "select",
        options,
      };
    }
  );

  let newItemFormElements: any = [
    {
      name: "productName",
      label: "Product",
      type: "text",
      disabled: true,
      rules: [{ required: true, message: "Product Name is required" }],
    },
    {
      name: "name",
      label: "Item Name",
      type: "text",
      rules: [{ required: true, message: "Price List Name is required" }],
    },
    {
      name: "shop",
      label: "Shop",
      type: "select",
      options: shopState.shops,
    },
  ];

  newItemFormElements.push(...additionalFields);

  props.productItem?.specification?.forEach((spec: any) => {
    form.setFieldValue(spec[0], spec[1]);
  });

  const submit = (values: any) => {
    let data: any = {};
    let specification = [];
    data.product = productState?.product?._id;
    data.name = values.name;
    data.prices = [
      {
        value: values.price,
        shop: "644293a789dd516819854077",
      },
    ];
    delete values.productName;
    delete values.price;
    delete values.shop;
    delete values.productName;
    delete values.name;

    for (let key in values) {
      specification.push([key, values[key]]);
    }
    data.specification = specification;
    data.prices = [
      {
        value: 150000,
        shop: "644293a789dd516819854077",
      },
    ];

    if (props.productItem?._id !== undefined) {
      dispatch(
        updateAction(
          updateProductItemService,
          props.productItem?._id,
          data,
          token,
          () => dispatch(productActions.setFetchedProductItems(false)),
          () => props.close()
        )
      );
    }
  };

  return (
    <GenericForm
      open={true}
      close={() => props.close()}
      form={form}
      styles="horizontal"
      title="Update Price List"
      formElements={newItemFormElements}
      onSubmit={submit}
    />
  );
};

export default UpdatePriceList;
