import { Form, Input, Typography } from "antd";
import styles from "../index.module.scss";
import phone_otp from "../../../assets/images/phone_otp.png";
import logo from "../../../assets/images/logo-final.png";
import { LoadingButton } from "@mui/lab";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import { primaryColor } from "../../../assets/colors/colors";
import { useDispatch, useSelector } from "react-redux";
import { updateAction } from "../../../store/app/appActions";
import { updateUserService } from "../../../store/app/user/userService";
import ResponseDialog from "../../../components/ResponseDialog";
import { appActions } from "../../../store/app";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const VerifyOTP = () => {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const authState = useSelector((state: any) => state.auth);

  const submitOTP = (otp: string) => {
    console.log("submitttting ", otp);
    if (otp === authState?.user?.otpCode) {
      dispatch(
        appActions.setHttpResponse({
          statusCode: 200,
          status: "success",
          message: "Phone Number verified Successfully! login with your new credentials",
        })
      );
      // await updateUserService()
      // setTimeout(() => {
      //   navigate('/login');
      // }, 1500)
    } else {
      dispatch(
        appActions.setHttpResponse({
          statusCode: 400,
          status: "fail",
          message: "OTP is incorrect, please try again!",
        })
      );
    }
    console.log("is correct ");
    // dispatch(updateAction(updateUserService, a))
  };

  useEffect(() => {
    console.log("Otp ", otp);
    if (otp.length === 6) {
      submitOTP(otp);
    }
  }, [otp]);

  return (
    <div className={styles.authBackground}>
      <div className={styles.authCard}>
        <div className={styles.authEllipse}></div>
        <div className={styles.authCardContent}>
          <div className="w-full border-r border-5 border-gray-600 text-center flex items-center justify-center flex-col">
            <div className="text-blue-800 font-bold text-3xl text-center">
              OTP Verification
            </div>
            <div className="text-sm w-2/3 mb-6">
              We sent you one time password on this mobile number. +250788329340
            </div>
            <img style={{ height: "10rem" }} alt="otp" src={phone_otp} />
            <Form
              layout={formLayout}
              form={form}
              onFinish={() => submitOTP(otp)}
              style={{ width: "75%", marginTop: "1rem" }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1rem 0",
                }}
                renderSeparator={<span className="w-3"></span>}
                renderInput={(props: any) => (
                  <input
                    {...props}
                    style={{
                      border: `1px solid ${primaryColor}`,
                      borderRadius: "5px",
                      height: "2.3rem",
                      maxWidth: "2.2rem",
                      textAlign: "center",
                      backgroundColor: "transparent",
                      fontSize: "1.3rem",
                    }}
                  />
                )}
              />
              <div>Did not receive otp? Resend OTP</div>
              <LoadingButton
                variant="contained"
                type="submit"
                style={{ width: "100%", backgroundColor: primaryColor }}
              >
                Submit
              </LoadingButton>
            </Form>
          </div>
          <div className="w-full flex items-center justify-center">
            <img style={{ height: "10rem" }} alt="otp" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
