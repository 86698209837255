import { Box, Button, TablePagination } from "@mui/material";
import Layout from "../../components/Layout";
import styles from "../index.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { AppState, appActions } from "../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../i18n";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import NewItem from "./NewItem";
import Storage from "../../utils/storage";
import { Typography } from "antd";
import DynamicListView from "../../components/DynamicListView";
import {
  getProductsAction,
  searchProductAction,
} from "../../store/products/product/productActions";
import { Product } from "../../interfaces/product/product";
import ProductCard from "./ProductCard";
import { ProductState, productActions } from "../../store/products";
import NoData from "../../components/NoData";
import SearchComponent2 from "../../components/SearchComponent2";
import ConfirmModal from "../../components/ConfirmModal";
import { deleteAction } from "../../store/app/appActions";
import {
  deleteProductService,
  setProductImageService,
} from "../../store/products/product/productService";
import UploadImage from "../../components/UploadImage";
import ProductCardSkeleton from "./ProductItem/ProductCardSkeleton";
import { ShopState } from "../../store/shop";

const Items = () => {
  const [viewGrid, setViewGrid] = useState(true);
  const [updateItem, setUpdateItem] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const appState: AppState = useSelector((state: any) => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const exportExcelHandler = () => {};
  const [uploading, setUploading] = useState(false);
  const [uploadPicture, setUploadPicture] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [searchText, setSearchText] = useState(0);
  const productState: ProductState = useSelector((state: any) => state.product);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    if (!productState.fetchedProducts) {
      dispatch(
        getProductsAction(
          `?limit=${rowsPerPage}&page=${page}&businessCategory=${shopState.shop?.shopCategory}`,
          token
        )
      );
    }
  }, [productState.fetchedProducts, page]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(
          searchProductAction(
            `?text=${searchText}&company=${Storage.getUser()?.company?._id}`,
            token
          )
        );
        setIsInit(false);
      } else {
        if (
          !productState.fetchingProducts &&
          productState.fetchedProducts &&
          !isInit
        ) {
          console.log("second else if");
          dispatch(productActions.setStockProducts(productState.products));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    dispatch(productActions.setFetchedProducts(false));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(productActions.setFetchedProducts(false));
  };

  const uploadProductImageHandler = async (data: any) => {
    try {
      if (product?._id !== null) {
        setUploading(true);
        const res = await setProductImageService(product!._id, data, token);
        setUploading(false);
        if (res.status === 200) {
          dispatch(productActions.setFetchedProducts(false));
          setUploadPicture(false);
        }
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleDeleteItem = () => {
    if (product?._id) {
      dispatch(
        deleteAction(
          deleteProductService,
          product?._id,
          token,
          "Product",
          () => {},
          () => dispatch(productActions.setFetchedProducts(false))
        )
      );
    }
  };

  return (
    <Layout>
      <Box className={styles.searchComponent}>
        <Typography className="font-bold text-xl">Products</Typography>
        <SearchComponent2
          placeholder={"Search Products"}
          setSearchText={setSearchText}
        />
        <Button
          className={styles.primaryButton}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: "add" }));
          }}
        >
          Add Product
        </Button>
      </Box>
      <div className="flex justify-between">
        <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
        <div className="flex items-center me-5">
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          ></LoadingButton>
        </div>
      </div>
      {productState.fetchingProducts && (
        <div className="h-[66vh] flex">
          <ProductCardSkeleton />
        </div>
      )}
      {!productState.fetchingProducts && (
        <>
          <Box className="flex flex-wrap mt-2 overflow-y-scroll h-[66vh]">
            {productState?.stockProducts?.length > 0 &&
              productState?.stockProducts?.map((product: Product) => (
                <ProductCard
                  onUpdate={() => {
                    setProduct(product);
                    setUpdateItem(true);
                  }}
                  onDelete={() => {
                    setProduct(product);
                    dispatch(appActions.setIsDeleting(true));
                  }}
                  onUpload={() => {
                    setProduct(product);
                    setUploadPicture(true);
                  }}
                  key={product._id}
                  product={product}
                />
              ))}
            {productState?.products?.length === 0 && <NoData />}
          </Box>
          <TablePagination
            component="div"
            count={productState.productsCount}
            labelRowsPerPage={"Products per page"}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      {appState.openForm && (
        <NewItem
          open={appState.openForm.open}
          close={() => dispatch(appActions.closeForm())}
        />
      )}
      {updateItem && (
        <NewItem
          open={updateItem}
          close={() => setUpdateItem(false)}
          action="update"
          product={product}
        />
      )}
      {uploadPicture && (
        <UploadImage
          open={uploadPicture}
          close={() => setUploadPicture(false)}
          title="Set Product Image"
          uploading={uploading}
          uploadHandler={uploadProductImageHandler}
        />
      )}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={() => handleDeleteItem()}
          content={`Please confirm to delete ${product?.name} Product`}
        />
      )}
    </Layout>
  );
};

export default Items;
