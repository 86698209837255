import { Button, Skeleton } from "@mui/material";
import styles from "../index.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AppState } from "../../store/app";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import NewPurchase from "./NewPurchase";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import Storage from "../../utils/storage";
import { getPurchasesAction } from "../../store/purchase/purchaseActions";
import { Purchase } from "../../interfaces/purchase";
import { calculateTotalPrice, setResponseAction } from "../../utils/functions";
import FilterListIcon from "@mui/icons-material/FilterList";
import { PurchasesState, purchasesActions } from "../../store/purchase";
import PurchaseDetails from "./PurchaseDetails";
import NoData from "../../components/NoData";
import illustration from "../../assets/images/illu_purchases.png";
import ListLoadingItem from "../../components/ListLoadingItem";
import { ShopState } from "../../store/shop";
import { useTranslation } from "../../i18n";

const Purchases = () => {
  const token = Storage.getToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addPurchase, setAddPurchase] = useState(false);
  const [query, setQuery] = useState(
    `?company=${Storage.getUser()?.company?._id}`
  );
  const [purchase, setPurchase] = useState<Purchase | null>();
  const appState: AppState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [updatePurchase, setUpdatePurchase] = useState(false);
  const purchaseState: PurchasesState = useSelector(
    (state: any) => state.purchase
  );

  console.log("purchases reloading");

  useEffect(() => {
    if (!purchaseState.fetchedPurchases) {
      dispatch(getPurchasesAction(query, token));
    }
  }, [purchaseState.fetchedPurchases]);

  useEffect(() => {
    if (shopState?.shop?._id) {
      setQuery(`?shop=${shopState?.shop?._id}`);
      dispatch(purchasesActions.setFetchedPurchases(false));
    } else {
      setQuery(`?company=${Storage.getUser()?.company?._id}`);
      dispatch(purchasesActions.setFetchedPurchases(false));
    }
  }, [shopState.shop]);

  return (
    <>
      {addPurchase && <NewPurchase close={() => setAddPurchase(false)} />}
      {updatePurchase && (
        <NewPurchase
          action="update"
          purchase={purchase}
          close={() => setUpdatePurchase(false)}
        />
      )}
      {!addPurchase && !updatePurchase && (
        <>
          <div className="flex justify-between my-4">
            <Typography className="font-bold text-xl">
              {t("purchases")}
            </Typography>
            <Button
              className={styles.primaryButton}
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              onClick={() => {
                if (shopState.shop) {
                  setAddPurchase(true);
                } else {
                  dispatch(
                    setResponseAction(null, "fail", "Please choose a shop")
                  );
                }
              }}
            >
              {t("addPurchase")}
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="bg-white pt-3">
                <div className="flex items-center">
                  <div className="flex border rounded-md ms-4 mb-3 p-2 basis-5/6">
                    <SearchIcon className="me-3" />
                    <input
                      placeholder="Search By Supplier"
                      className="w-full outline-none"
                      type="text"
                    />
                  </div>
                  <FilterListIcon className="basis-1/6 cursor-pointer" />
                </div>
                <hr />
                {!appState.isFetching && (
                  <div style={{ height: "73vh", overflowY: "scroll" }}>
                    {purchaseState?.purchases?.length > 0 &&
                      purchaseState?.purchases?.map(
                        (currentPurchase: Purchase) => (
                          <div
                            key={currentPurchase._id}
                            style={{
                              backgroundColor:
                                currentPurchase._id === purchase?._id
                                  ? "#EBF6FF"
                                  : "white",
                            }}
                            className="p-2 m-4 rounded-md cursor-pointer shadow-md"
                            onClick={() => setPurchase(currentPurchase)}
                          >
                            <div className="flex justify-between">
                              <div>
                                {currentPurchase?.supplier?.name ??
                                  t("supplier")}
                              </div>
                              <ArrowForwardIosIcon style={{ height: "1rem" }} />
                            </div>
                            <div>{currentPurchase.list.length} Items</div>
                            <div className="flex justify-between">
                              <div className="font-bold">
                                {calculateTotalPrice(currentPurchase.list)} RWF
                              </div>
                              <div>
                                {currentPurchase.date
                                  .substring(0, 10)
                                  .replaceAll("-", ".")}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    {purchaseState?.purchases?.length === 0 && <NoData />}
                  </div>
                )}
                {appState.isFetching && (
                  <div style={{ height: "32.3rem" }}>
                    <ListLoadingItem />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              {purchase && (
                <PurchaseDetails
                  purchase={purchase}
                  close={() => setPurchase(null)}
                  setUpdatePurchase={setUpdatePurchase}
                />
              )}
              {!purchase && (
                <div className="h-[81vh] bg-white w-full flex items-center justify-center">
                  <img
                    className="w-[18vw] h-[40vh]"
                    src={illustration}
                    alt="Purchase"
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Purchases;
