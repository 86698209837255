import { useState } from "react";
import Layout from "../../components/Layout";
import Purchases from "./Purchases";
import Suppliers from "./suppliers/suppliers";
import PurchasesOverview from "./PurchasesOverview";

const PurchasesMain = () => {
  const pages = ["Purchases", "Suppliers"];
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Layout pages={pages} activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 0 && <Purchases />}
      {activeTab === 1 && <Suppliers />}
      {activeTab === 2 && <PurchasesOverview />}
    </Layout>
  );
};

export default PurchasesMain;
