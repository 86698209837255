import { createSlice } from "@reduxjs/toolkit";
import { Shop } from "../../interfaces/shop";

export interface ShopState {
  shops: Shop[];
  shop: Shop | null;
  fetchedShops: boolean;
}

const initialState: ShopState = {
  shops: [],
  shop: null,
  fetchedShops: false,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShops(state, action) {
      state.shops = action.payload;
    },
    setShop(state, action) {
      state.shop = action.payload;
    },
    setFetchedShops(state, action) {
      state.fetchedShops = action.payload;
    },
    resetShopState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
