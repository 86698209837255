import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import {
  getProductUnitsService,
  getProductsService,
  searchProductService,
} from "./productService";
import { productActions } from "..";
import { getProductItemsService } from "../productItem/productItemService";

// GET ALL
export const getProductsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(productActions.setFetchingProducts(true));
      const res = await getProductsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setProducts(res.data));
        dispatch(productActions.setStockProducts(res.data));
        dispatch(productActions.setProductsCount(res.total));
        dispatch(productActions.setFetchedProducts(true));
      }
      dispatch(productActions.setFetchingProducts(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductItemsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(productActions.setFetchingProductItems(true));

      // Product Items
      const res = await getProductItemsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setProductItems(res.data));
      }

      // Product Units
      const unitsRes = await getProductUnitsService(query, token);
      if (requestFailed(unitsRes)) {
        dispatch(setResponseAction(unitsRes, "fail", res.message));
      } else {
        dispatch(productActions.setProductUnits(unitsRes.data));
        dispatch(productActions.setFetchedProductItems(true));
      }

      dispatch(productActions.setFetchingProductItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportProductsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getProductsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchProductAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(productActions.setFetchingProducts(true));
      const res = await searchProductService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setStockProducts(res.data));
        dispatch(productActions.setProductsCount(res.total));
        dispatch(productActions.setFetchedProducts(true));
      }
      dispatch(productActions.setFetchingProducts(false));
    } catch (err) {
      console.log(err);
    }
  };
};
