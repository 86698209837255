import { salesActions } from ".";
import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../utils/functions";
import { appActions } from "../app";
import { getCustomersService } from "../app/customer/customerService";
import { getSalesService, searchSalesService } from "./salesService";

// GET ALL
export const getSalesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getSalesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(salesActions.setSales({sales: res.data, count: res.count, fetched: true}));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportSalesAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getSalesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchSalesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchSalesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(salesActions.setSales({sales: res.data, count: res.count, fetched: true}));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchElementsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(salesActions.setFetchingFormElements(true));

      // FETCH CUSTOMERS
      const suppliers = await getCustomersService(query, token);
      if (requestFailed(suppliers)) {
        dispatch(setResponseAction(suppliers, "fail", suppliers.message));
      } else {
        dispatch(salesActions.setCustomers(suppliers.data));
      }

      dispatch(salesActions.setFetchedFormElements(true));
      dispatch(salesActions.setFetchingFormElements(false));
    } catch (err) {
      console.log(err);
    }
  };
};
