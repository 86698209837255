import { createSlice } from "@reduxjs/toolkit";
import { Sale } from "../../interfaces/sales/sale";
import { Customer } from "../../interfaces/customer";

export interface SalesState {
  sales: Sale[];
  fetchedSales: boolean;
  fetchedFormElements: boolean;
  fetchingFormElements: boolean;
  count: number;
  customers: Customer[];
}

const initialState: SalesState = {
  sales: [],
  fetchedSales: false,
  count: 0,
  fetchedFormElements: false,
  fetchingFormElements: false,
  customers: [],
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setSales(state, action) {
      state.sales = action.payload.sales;
      state.count = action.payload.count;
      state.fetchedSales = action.payload.fetched;
    },
    setSalesCount(state, action) {
      state.count = action.payload;
    },
    setFetchedSales(state, action) {
      state.fetchedSales = action.payload;
    },
    setFetchedFormElements(state, action) {
      state.fetchedFormElements = action.payload;
    },
    setFetchingFormElements(state, action) {
      state.fetchingFormElements = action.payload;
    },
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    resetSalesState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const salesActions = salesSlice.actions;

const salesReducer = salesSlice.reducer;

export default salesReducer;
