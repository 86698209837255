import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/auth/login";
import SalesMain from "../pages/sales/SalesMain";
import Settings from "../pages/settings/Settings";
import Items from "../pages/items/Items";
import SendOTP from "../pages/auth/otp/SendOtp";
import Register from "../pages/auth/Register/Register";
import VerifyOTP from "../pages/auth/otp/verifyPhone";
import Branches from "../pages/branch/Branches";
import Support from "../pages/support/Support";
import PurchasesMain from "../pages/purchases/PurchasesMain";
import Signup from "../pages/auth/signup/signup";
import ProductItemDetails from "../pages/items/ProductItem/ProductItemsDetails";
import ProductItems from "../pages/items/ProductItem/ProductItems";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/sales" element={<SalesMain />} />
      <Route path="/login" element={<Login />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/purchases" element={<PurchasesMain />} />
      <Route path="/products" element={<Items />} />
      <Route
        path="/products/productitem/:id"
        element={<ProductItemDetails />}
      />
      <Route path="/products/:id" element={<ProductItems />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/sendotp" element={<SendOTP />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/branches" element={<Branches />} />
      <Route path="/support" element={<Support />} />
    </Routes>
  );
};

export default MainRouter;
