import { createSlice } from "@reduxjs/toolkit";
import { HttpResponse, OpenForm } from "../../interfaces/store";
import { Shop } from "../../interfaces/shop";
import { Customer } from "../../interfaces/customer";
import { User } from "../../interfaces/user";
import { Supplier } from "../../interfaces/supplier";
import { Company } from "../../interfaces/company";

export interface AppState {
  updateNeeded: boolean;
  isFetching: boolean;
  drawerFetching: boolean;
  fetchingFormItems: boolean;
  isExportingExcel: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  fetchedStaff: boolean;
  isFetchingDashboard: false;
  selectedProject: any;
  projectId: string;
  fetchedSuppliers: boolean;
  fetchedCustomers: boolean;
  customers: Customer[];
  count: number;
  navIndex: number;
  projects: any;
  staff: any;
  openFilter: boolean;
  openForm: OpenForm;
  chartData: any;
  httpResponse: HttpResponse;
  dashboard: any;
  users: User[];
  fetchedUsers: boolean;
  suppliers: Supplier[];
  total: number;
  company: Company | undefined;
}

const initialState: AppState = {
  updateNeeded: false,
  isFetching: false,
  drawerFetching: false,
  fetchingFormItems: false,
  isExportingExcel: false,
  isSubmitting: false,
  isDeleting: false,
  isFetchingDashboard: false,
  fetchedStaff: true,
  selectedProject: {},
  projectId: "",
  count: 0,
  navIndex: 0,
  projects: [],
  staff: [],
  openFilter: false,
  openForm: {
    open: false,
    action: "",
  } as OpenForm,
  chartData: {},
  httpResponse: {
    statusCode: 0,
    message: "",
    status: "",
  } as HttpResponse,
  fetchedSuppliers: false,
  fetchedCustomers: false,
  users: [],
  fetchedUsers: false,
  customers: [],
  suppliers: [],
  dashboard: {},
  total: 0,
  company: undefined,
};

const appSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    setFetchedUsers(state, action) {
      state.fetchedUsers = action.payload;
    },
    setFetchedStaff(state, action) {
      state.fetchedStaff = action.payload;
    },
    changeSelectedProject(state, action) {
      state.selectedProject = action.payload.project;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setUpdateNeeded(state, action) {
      state.updateNeeded = action.payload;
    },
    setIsExportingExcel(state, action) {
      state.isExportingExcel = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setDrawerIsFetching(state, action) {
      state.drawerFetching = action.payload;
    },
    setIsSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    setFetchingDashboard(state, action) {
      state.isFetchingDashboard = action.payload;
    },
    setFetchingFormItems(state, action) {
      state.fetchingFormItems = action.payload;
    },
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
    setOpenFilter(state, action) {
      state.openFilter = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload.count;
    },
    setProject(state, action) {
      state.projects = action.payload.projects;
      state.updateNeeded = false;
      state.isFetching = false;
    },
    resetError(state) {
      state.httpResponse.statusCode = 0;
      state.httpResponse.message = "";
    },
    setHttpResponse(state, action) {
      state.httpResponse.statusCode = action.payload.statusCode;
      state.httpResponse.status = action.payload.status;
      state.httpResponse.message = action.payload.message;
    },
    setOpenForm(state, action) {
      state.openForm = {
        open: action.payload.open,
        action: action.payload.action,
      };
    },
    closeForm(state) {
      state.openForm = { open: false, action: "" };
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setFetchedCustomers(state, action) {
      state.fetchedCustomers = action.payload;
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    setFetchedSuppliers(state, action) {
      state.fetchedSuppliers = action.payload;
    },
    addToTotal(state, action) {
      state.total += action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    resetTotal(state) {
      state.total = 0;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    resetAppState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
