import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../store/app";
import { useState, useEffect } from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import { Form, Input, Typography, type SelectProps, Select, Space } from "antd";
import {
  addProductService,
  updateProductService,
} from "../../store/products/product/productService";
import Storage from "../../utils/storage";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import FormHeader from "../../components/FormHeader";
import styles from "../../components/index.module.scss";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "../../i18n";
import {
  addProductUnitService,
  getProductUnitsService,
} from "../../store/products/product/productUnitsService";
import { productActions } from "../../store/products";
import { Product } from "../../interfaces/product/product";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export interface NewItemProps {
  open: boolean;
  close: Function;
  action?: string;
  product?: Product | null;
}

const NewItem = (props: NewItemProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const tags: SelectProps["options"] = [];
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const fetchUnits = async (productId: any) => {
    const res = await getProductUnitsService(`?product=${productId}`, token);
    const units = res.data[0].formular.filter(
      (unit: any) => unit.default === true
    );
    console.log("ressssssss ", units);
    form.setFieldValue("standardUnit", units[0].label);
  };

  const resetFields = () => {
    form.resetFields([
      "name",
      "standardUnit",
      "formular",
      "categories",
      "variants",
    ]);
  };

  useEffect(() => {
    if (props?.action === "update") {
      fetchUnits(props?.product?._id);
      form.setFieldsValue({
        name: props?.product?.name,
        categories: props?.product?.categories,
        variants: props?.product?.variants,
      });
    }
  }, []);

  const submit = async (values: any) => {
    if (!values.formular) {
      values.formular = [];
    }
    values.formular.push({
      label: values.standardUnit,
      value: 1,
      default: true,
    });
    delete values.standardUnit;
    try {
      dispatch(appActions.setIsSubmitting(true));
      values.company = Storage.getUser()?.company?._id;
      if (props?.action === "update" && props?.product?._id) {
        const response = await updateProductService(
          props?.product?._id,
          values,
          token
        );
        console.log("responseeeeeee ", response);
      } else {
        let product = await addProductService(values, token);
        if (product.status === 201) {
          await addProductUnitService(
            {
              product: product.data._id,
              formular: values.formular,
              company: Storage.getUser()?.company?._id,
            },
            token
          );
        }
      }
    } catch (error: any) {
      console.log("error ", error);
    } finally {
      dispatch(appActions.setIsSubmitting(false));
      props.close();
      dispatch(productActions.setFetchedProducts(false));
      resetFields();
    }
  };

  return (
    <Dialog
      classes={{
        paper: styles.genericFormVertical,
      }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title="New Product" close={() => props.close()} />
      <DialogContent>
        <Form
          {...layout}
          layout={formLayout}
          form={form}
          name="control-hooks"
          onFinish={submit}
          // style={{ maxWidth: 600 }}
          className="my_form"
        >
          <Form.Item
            name="name"
            label="Name"
            className={styles.forms_input_vertical}
            rules={[{ required: true, message: "Product Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="standardUnit"
            label="Standard Unit"
            className={styles.forms_input_vertical}
          >
            <Input placeholder="ex Liter, Kg" />
          </Form.Item>
          <Form.List name="formular">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <div className="flex items-center">
                    <Typography className="me-1">Other Units</Typography>
                    <AddIcon
                      style={{ cursor: "pointer", height: "1rem" }}
                      onClick={() => add()}
                    />
                  </div>
                </Form.Item>
                <div className="flex flex-wrap">
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0.1rem 0",
                        width: "33%",
                      }}
                    >
                      <Space.Compact>
                        <Form.Item {...restField} name={[name, "label"]}>
                          <Input placeholder="Unit" />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, "value"]}>
                          <Input
                            placeholder={
                              form.getFieldValue("standardUnit") + "s"
                            }
                          />
                        </Form.Item>
                      </Space.Compact>
                      <MinusCircleOutlined
                        style={{ marginLeft: "0.1rem", marginRight: "0.3rem" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.List>
          <Form.Item
            name="categories"
            label="Categories"
            className={styles.forms_input_vertical}
          >
            <Select mode="tags" style={{ width: "100%" }} options={tags} />
          </Form.Item>
          <Form.List name="variants">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <div className="flex items-center">
                    <Typography className="me-1">Variants</Typography>
                    <AddIcon className="cursor-pointer" onClick={() => add()} />
                  </div>
                </Form.Item>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "1rem 0",
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "label"]}
                      style={{ flexBasis: "30%" }}
                      rules={[{ required: true, message: "Missing Variant" }]}
                    >
                      <Input placeholder="Variants" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ flexBasis: "70%", marginLeft: "0.5rem" }}
                      name={[name, "value"]}
                      rules={[{ required: true, message: "Missing Values" }]}
                    >
                      <Select mode="tags" style={{ width: "100%" }} />
                      {/* <Input placeholder="Values" /> */}
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <Box className={styles.form_action}>
            <Button
              onClick={() => props.close()}
              className={styles.form_cancelButton}
              variant="contained"
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              className={styles.form_confirmButton}
              loading={appState.isSubmitting}
              size="large"
              // onClick={() => {
              //   handleLogin();
              // }}
            >
              {props?.action === "update" ? "Update" : t("save")}
            </LoadingButton>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default NewItem;
