import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "../pages/index.module.scss";
import { ChangeEventHandler, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth";
import { useNavigate } from "react-router-dom";
import user from "../assets/images/user.png";
import LanguageIcon from "@mui/icons-material/Language";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import i18n, { useTranslation } from "../i18n";
import en from "../assets/images/uk.png";
import fr from "../assets/images/fr.png";
import kin from "../assets/images/rw.png";
import sw from "../assets/images/sw.png";
import { AppState, appActions } from "../store/app";
import { Shop } from "../interfaces/shop";
import { logoutAction } from "../store/auth/authActions";
import Storage from "../utils/storage";
import { ShopState, shopActions } from "../store/shop";

export interface AppBarProps {
  name?: string;
  pages?: string[];
  activeTab?: number;
  setActiveTab?: Function;
}

export interface SettingsItem {
  label: string;
  icon: any;
  text: string;
}

export interface LanguageItem {
  language: string;
  id: "en" | "fr" | "kin" | "sw";
  flag: any;
}

const AppBar = (props: AppBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(
    null
  );
  const setActiveTab =
    props.setActiveTab !== undefined ? props.setActiveTab : () => {};
  const languages: LanguageItem[] = [
    { language: "Kinyarwanda", id: "kin", flag: kin },
    { language: "French", id: "fr", flag: fr },
    { language: "English", id: "en", flag: en },
    { language: "Swahili", id: "sw", flag: sw },
  ];
  const settingsItems: SettingsItem[] = [
    {
      label: t("profile"),
      icon: <AccountCircleIcon style={{ color: "currentColor" }} />,
      text: "Profile",
    },
    {
      label: t("language"),
      icon: <LanguageIcon style={{ color: "currentColor" }} />,
      text: "Language",
    },
    {
      label: t("logout"),
      icon: <LogoutIcon style={{ color: "currentColor" }} />,
      text: "Logout",
    },
  ];
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    dispatch(logoutAction());
    navigate("/login");
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenLanguagesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const shopChangedHandler = (event: any) => {
    const selectedShop = shopState.shops.filter(
      (currentShop: Shop) => currentShop._id === event.target.value
    );
    if (selectedShop.length === 1) {
      console.log("Shop changed handler ", selectedShop[0]);
      dispatch(shopActions.setShop(selectedShop[0]));
    } else {
      dispatch(shopActions.setShop(null));
    }
  };

  return (
    <Box className="myAppBar">
      <Typography>Hello {props?.name}</Typography>
      <List className={styles.listItems}>
        {props.pages &&
          props.setActiveTab !== undefined &&
          props.pages.map((page: string, index: number) => (
            <ListItem
              key={page}
              selected={index === props.activeTab}
              onClick={() => setActiveTab(index)}
              classes={{
                root: styles.listItem,
                selected: styles.listItemSelected,
              }}
            >
              <ListItemText className={styles.listItemRoot}>
                <Typography className="whitespace-nowrap">{page}</Typography>
              </ListItemText>
              {/* <Typography textAlign="center"></Typography> */}
            </ListItem>
          ))}
      </List>
      <Box className="flex items-center flex-grow-0">
        <select
          className="text-blue-600 font-semibold uppercase outline-none me-4"
          onChange={shopChangedHandler}
          value={shopState?.shop?._id ?? "all"}
        >
          <option value="all">All</option>
          {shopState.shops.map((shop: Shop) => (
            <option key={shop._id} value={shop._id}>
              {shop.name}
            </option>
          ))}
        </select>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src={Storage.getUser()?.image?.url} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settingsItems.map((setting: SettingsItem) => (
            <MenuItem
              key={setting.text}
              onClick={(event: any) => {
                if (setting.text === "Logout") {
                  handleCloseUserMenu();
                  logout();
                } else if (setting.text === "Profile") {
                  handleCloseUserMenu();
                  navigate("/settings");
                } else {
                  // handleCloseUserMenu();
                  handleOpenLanguagesMenu(event);
                }
              }}
            >
              {setting.icon}
              <Typography textAlign="center">{setting.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="languages"
          anchorEl={anchorElLanguage}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElLanguage)}
          onClose={() => {
            setAnchorElLanguage(null);
            handleCloseUserMenu();
          }}
        >
          {languages.map((language: LanguageItem, index: number) => (
            <MenuItem
              key={index}
              onClick={() => {
                i18n.changeLanguage(language.id);
                setAnchorElLanguage(null);
                handleCloseUserMenu();
              }}
            >
              <img
                alt="flag"
                style={{ height: "15px", marginRight: "10px" }}
                src={language.flag}
              />
              <Typography>{language.language}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default AppBar;
