import styles from "../index.module.scss";
import { Box, Menu, MenuItem } from "@mui/material";
import { Product } from "../../interfaces/product/product";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/app";
import { Typography } from "antd";
import { useState } from "react";
import { initials } from "../../utils/functions";
import { productActions } from "../../store/products";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface ProductCardProps {
  product: Product;
  onUpload: Function;
  onUpdate: Function;
  onDelete: Function;
}

const ProductCard = (props: ProductCardProps) => {
  const appState: AppState = useSelector((state: any) => state.app);
  const [anchorElProduct, setAnchorElProduct] = useState<HTMLElement | null>();

  const dispatch = useDispatch();

  return (
    <Box className={styles.productCard}>
      {Boolean(anchorElProduct) && (
        <Menu
          id="basic-menu"
          anchorEl={anchorElProduct}
          open={Boolean(anchorElProduct)}
          onClose={() => setAnchorElProduct(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              props.onUpload();
              setAnchorElProduct(null);
            }}
          >
            <ImageOutlinedIcon />
            <Typography>Image</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onUpdate();
              setAnchorElProduct(null);
            }}
          >
            <AutorenewIcon />
            <Typography>Update</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onDelete();
              setAnchorElProduct(null);
            }}
          >
            <DeleteOutlineOutlinedIcon />
            <Typography>Delete</Typography>
          </MenuItem>
        </Menu>
      )}
      <div className="flex justify-end">
        <MoreVertIcon
          onClick={(event: any) => {
            setAnchorElProduct(event.target);
          }}
          style={{
            marginTop: "0.3rem",
            position: "absolute",
            zIndex: "1000",
          }}
        />
      </div>
      <Link
        onClick={() => {
          dispatch(productActions.setProduct(props.product));
        }}
        to={`/products/${props.product._id}`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          display: "block",
          width: "100%",
          padding: "0.3rem",
        }}
      >
        {!props?.product?.image?.url && (
          <div className="bg-gray-200 rounded-md h-36 w-full flex justify-center items-center me-2">
            <Typography className="whitespace-nowrap font-semibold text-3xl text-gray-500">
              {initials(props?.product?.name)}
            </Typography>
          </div>
        )}
        {props?.product?.image?.url && (
          <img
            className="h-36 w-full"
            src={props?.product?.image?.url}
            alt="pff"
          />
        )}
        <div className="w-full">
          <div className="w-full text-center">
            <Typography className={styles.productName}>
              {props.product.name}
            </Typography>
          </div>

          <div className="mb-4">
            <Typography className="text-primaryGreen rounded-md px-1 w-auto text-center">
              {props.product.categories.join(", ")}
            </Typography>
          </div>
        </div>
      </Link>
    </Box>
  );
};

export default ProductCard;
