import store from "store";
import { authActions } from ".";
import i18n from "../../i18n";
import { loginService, signupService } from "./authServices";
import { setResponseAction } from "../../utils/functions";
import { appActions } from "../app";
import { dashboardActions } from "../dashboard";
import { salesActions } from "../sales";
import { purchasesActions } from "../purchase";
import { productActions } from "../products";
import { shopActions } from "../shop";

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      dispatch(authActions.login({ status: null }));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set("x-auth-token", res?.data?.data?.token);
        store.set("user", res?.data?.data?.user);
      } else if (res?.status === 404) {
        dispatch(
          setResponseAction(res, "fail", `${i18n.t("incorrectCredentials")}`)
        );
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(authActions.login({ ...res?.data, status: res?.status }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const logoutAction = () => {
  return async (dispatch: any) => {
    try {
      store.remove("x-auth-token");
      store.remove("user");
      dispatch(authActions.logout());

      //RESET STATES;
      dispatch(appActions.resetAppState());
      dispatch(dashboardActions.resetDashboardState());
      dispatch(salesActions.resetSalesState());
      dispatch(purchasesActions.resetPurchaseState());
      dispatch(productActions.resetProductState());
      dispatch(shopActions.resetShopState());
    } catch (err) {
      console.log(err);
    }
  };
};

export const signupAction = (data: {}, onSuccess: Function) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      dispatch(authActions.login({ status: null }));
      const res = await signupService(data);
      if (res?.status === 201) {
        setResponseAction(res, "success", `${i18n.t("accountCreated")}`);
        onSuccess();
      }
      dispatch(authActions.setIsFetching(false));
      dispatch(authActions.login({ ...res?.data, status: res?.status }));
    } catch (err) {
      console.log(err);
    }
  };
};
