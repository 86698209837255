import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../store/app";
import { HttpResponse } from "../interfaces/store";
import { authActions } from "../store/auth";
import { useNavigate } from "react-router-dom";

const ResponseDialog = (props: any) => {
  const dispatch = useDispatch();
  const responseState: HttpResponse = useSelector(
    (state: any) => state.app.httpResponse
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(
      "Error Message",
      responseState.message,
      responseState.statusCode
    );
    if (responseState.statusCode !== 0) {
      setOpen(true);
      setTimeout(() => {
        dispatch(appActions.resetError());
        setOpen(false);

        if (
          (responseState.statusCode === 401 &&
            (responseState.message ===
              "The user belonging to this user does no longer exist." ||
              responseState.message ===
                "You are not logged in! Please log in to get access." ||
              responseState.message === "Unauthorized")) ||
          responseState.message === "jwt expired" ||
          responseState.message === "invalid signature" ||
          responseState.message === "FAILED TO VERIFY TOKEN !"
        ) {
          dispatch(authActions.logout());
          dispatch(appActions.resetError());
          return navigate("/login");
        }
      }, 2000);
    }
  }, [dispatch, navigate, responseState.message, responseState.statusCode]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      hideBackdrop={true}
      PaperProps={{ sx: { position: "fixed", top: 10, m: 0 } }}
    >
      {(responseState.status === "error" ||
        responseState.status === "fail") && (
        <Alert severity="error" onClose={() => props.setOpen(false)}>
          {responseState.message}
        </Alert>
      )}
      {responseState.status === "success" && (
        <Alert
          onClose={() => props.setOpen(false)}
          iconMapping={{
            success: <CheckCircleOutlineIcon fontSize="inherit" />,
          }}
        >
          {responseState.message}
        </Alert>
      )}
    </Dialog>
  );
};

export default ResponseDialog;
