import store from "store";

class Storage {

    public static getToken() {
        return store.get('x-auth-token');
    }

    public static getUser() {
        return store.get('user');
    }
}

export default Storage;