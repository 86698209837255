const translationsEn = {
  welcome: "Bienvenue",
  forms: "Forms",
  dashboard: "Dashboard",
  staff: "Staff",
  search: "Search",
  exportExcel: "Export File",
  months: "Months",
  sale: "Sale",
  logout: "Logout",
  report: "Report",
  client: "Client",
  paid: "Amount Paid",
  addSale: "Add Sale",
  kgUnitCost: "Kg unit cost",
  totalPrice: "Total",
  addNew: "Add New",
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  nationalID: "National ID",
  email: "Email",
  position: "Position",
  gender: "Gender",
  dob: "Date Of Birth",
  category: "Category",
  action: "Action",
  delete: "Delete",
  view: "View",
  update: "Update",
  cancel: "Cancel",
  confirmDelete: "Confirm Delete",
  confirmDeleteDesc: "Are you sure you want to delete",
  deleteSuccess: "deleted Successfully!",
  male: "Male",
  female: "Female",
  addStaff: "Add New Staff",
  updateStaff: "Update Staff",
  save: "Save",
  beginingDate: "Begining Date",
  filter: "Filter",
  amount: "Amount",
  paidAmount: "Paid Amount",
  createdBy: "Created By",
  createdAt: "Created At",
  updatedAt: "Updated At",
  dateOfBirth: "Date of Birth",
  status: "Status",
  photo: "Photo",
  work: "Work",
  documentName: "Name of Document",
  documentType: "Type of Document",
  document: "Document",
  add: "Add",
  days: "Days",
  cost: "Cost",
  remaining: "Remaining",
  afterNoon: "After Noon",
  morning: "Morning",
  L: "Liter",
  Kg: "Kilogram",
  m: "Meter",
  expense: "Expense",
  paymentMethod: "Payment Method",
  dontHaveAccountDesc: "Don't have account? Click Here to Sign Up",
  forgotPassword: "Forgot Password?",
  rememberMe: "Remember Me",
  purchase: "Purchase",
  sales: "Sales",
  stock: "Stock",
  settings: "Settings",
  items: "Items",
  profile: "Profile",
  language: "Language",
  createAccount: "Create Account",
  accountCreated: "Account created successfully!",
  incorrectCredentials: "Incorrect email or password",
  totalSales: "Total Sales",
  stockValue: "Stock Value",
  debts: "Debt",
  credits: "Credit",
  totalIncome: "Total Income",
  topSellingProducts: "Top Selling Products",
  customers: "Customers",
  supplier: "Suppliers",
  products: "Products",
  users: "Users",
  profit: "Profit",
  purchases: "Purchases",
  addPurchase: "Add Purchase",
};

export default translationsEn;
