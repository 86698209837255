import { Skeleton } from "@mui/material";

const ProductCardSkeleton = () => {
    return (
        <div className="flex">
                  {new Array(2).fill(null).map((_, index) => (
                    <div
                      key={index}
                      className="bg-white w-52 h-[32vh] mx-5 mb-5 pb-8  rounded-lg"
                    >
                      <div className="p-2">
                        <Skeleton
                          style={{ height: "21vh", transform: "none" }}
                        />
                      </div>
                      <hr />
                      <Skeleton
                        style={{
                          height: "2rem",
                          width: "75%",
                          marginLeft: "0.5rem",
                          marginTop: "0.8rem",
                        }}
                      />
                    </div>
                  ))}
                </div>
    );
}

export default ProductCardSkeleton;