import axios from "axios";
import { SERVER_URL } from "../../utils/constants";

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/login`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const signupService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/register`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};
