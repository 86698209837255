import { Box, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './index.module.scss';

interface FormHeaderProps {
  title: string;
  close: Function;
}

export default function FormHeader(props: FormHeaderProps) {
  return (
    <DialogTitle className={styles.genericForm_header}>
      <Box></Box>
      <Box className={styles.center}>
        <Typography className={styles.form_title}>{props?.title}</Typography>
      </Box>
      <CloseIcon className={styles.closeButton} onClick={() => props.close()} />
    </DialogTitle>
  );
}
