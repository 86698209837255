import Layout from "../../components/Layout";

const Branches = () => {
  return (
    <Layout>
      <div>Branches Page</div>
    </Layout>
  );
};

export default Branches;
