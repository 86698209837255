import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getProductUnitsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/productunit${query}`, token);
};

export const searchProductUnitService = async (
  query: string,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/productunit/search${query}`,
    token
  );
};

export const updateProductUnitService = async (
  productUnitId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/productunit${productUnitId}`,
    data,
    token
  );
};

export const addProductUnitService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/productunit`, data, token);
};

export const deleteProductUnitService = async (
  productUnitId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/productunit${productUnitId}`,
    token
  );
};
