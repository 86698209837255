import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import styles from "./index.module.scss";
import NoData from "./NoData";
import "./styles.css";
import { primaryPurple } from "../assets/colors/colors";
import { Typography } from "antd";

export default function TableComponent(props: any) {
  const {
    rows,
    columns,
    count,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    rowClickedHandler,
  } = props;

  return (
    <>
      {rows?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table
              className={styles.table}
              classes={{ root: styles.customTable }}
              size="medium"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  style={{ height: "45px", backgroundColor: "#CBC6FF" }}
                >
                  <TableCell
                    align={"left"}
                    style={{ padding: "0px 1rem !important" }}
                  ></TableCell>
                  {columns.map((column: any, index: number) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        fontWeight: "bold",
                        padding: "0px 1rem !important",
                      }}
                    >
                      <Typography className="text-primaryColor font-bold">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: number) => (
                  <TableRow
                    hover
                    style={{
                      cursor: "pointer",
                      height: "44px",
                      background: `${index % 2 == 0 ? '#f5faff' : 'white'}`,
                      borderRadius: 5,
                    }}
                    key={index}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align={"left"}
                      style={{
                        padding: "0px 1rem !important",
                        lineHeight: "0",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {columns.map((column: any, index: number) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={index}
                          style={{
                            padding: "0px 1rem !important",
                            lineHeight: "0",
                            fontSize: "0.75rem !important",
                          }}
                          align={column.align}
                          onClick={() =>
                            column.id === "action"
                              ? null
                              : rowClickedHandler(row.id, row.projectId, row)
                          }
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      {rows.length === 0 && <NoData />}
    </>
  );
}
