import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import styles from "./index.module.scss";
import { useTranslation } from "../i18n";

export interface SearchComponentProps {
  setSearchText: Function;
}

const SearchComponent = (props: SearchComponentProps) => {
  const { t } = useTranslation();
  let searchPlaceHolder: string = t("search");

  return (
    <div className={styles.search}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={searchPlaceHolder}
        classes={{
          root: styles.inputRoot,
          input: styles.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          props.setSearchText(event.target.value);
        }}
      />
    </div>
  );
};

export default SearchComponent;
