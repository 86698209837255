import { useState, useEffect } from "react";
import { useTranslation } from "../../../i18n";
import "./customers.scss";
import { Customer } from "../../../interfaces/customer";
import Storage from "../../../utils/storage";
import styles from "../../index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState, appActions } from "../../../store/app";
import { Box, Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LoadingContainer from "../../../components/LoadingContainer";
import SupplierCardValues from "../../purchases/suppliers/SupplierCardValues";
import SearchComponent from "../../../components/SearchComponent";
import { LoadingButton } from "@mui/lab";
import { getCustomersAction } from "../../../store/app/customer/customerActions";
import NewCustomer from "./NewCustomer";
import { renderMoney } from "../../../utils/functions";
import SearchComponent2 from "../../../components/SearchComponent2";
import { Typography } from "antd";
import NoData from "../../../components/NoData";

const Customers = () => {
  const token = Storage.getToken();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const { t } = useTranslation();

  const exportExcelHandler = () => {};

  useEffect(() => {
    if (!appState.fetchedCustomers) {
      dispatch(
        getCustomersAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }
  }, [appState.fetchedCustomers]);

  return (
    <>
      <Box className={styles.searchComponent}>
        <Typography className="font-bold text-xl">Customers</Typography>
        <SearchComponent2
          setSearchText={setSearchText}
          placeholder="Search Customer"
        />
        <Box style={{ display: "flex" }}>
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
          <Button
            className={styles.primaryButton}
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            onClick={() => {
              dispatch(appActions.setOpenForm({ open: true, action: "add" }));
            }}
          >
            Add Customer
          </Button>
        </Box>
      </Box>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <div className="flex flex-wrap mt-2 overflow-y-scroll h-[75vh]">
          {appState.customers.length === 0 && <NoData />}
          {appState.customers.length > 0 &&
            appState.customers.map((customer: Customer) => (
              <Box className={styles.supplierCard}>
                <Typography className={styles.userCardName}>
                  {customer.name}
                </Typography>
                <SupplierCardValues
                  label="Email"
                  value={customer.email ?? "N/A"}
                />
                <SupplierCardValues
                  label="Phone"
                  value={customer.phone ?? "N/A"}
                />
                <SupplierCardValues
                  label="Address"
                  value={customer.address ?? "N/A"}
                />
                <SupplierCardValues
                  label="Credit"
                  value={renderMoney(customer?.credit)}
                />
                <div className="flex justify-end text-gray-400 my-2">
                  <NoteAltOutlinedIcon />
                  <DeleteOutlineOutlinedIcon />
                </div>
              </Box>
            ))}
        </div>
      )}
      {appState.openForm.open && <NewCustomer />}
    </>
  );
};

export default Customers;
