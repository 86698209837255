import axios from "axios";
import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getUsersService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/user/users${query}`, token);
};

export const searchUserService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/user/users/search${query}`, token);
};

export const setProfilePictureService = async (userId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/user/upload-profile/${userId}`, data, token);
};

export const updateUserService = async (
  UserId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/users${UserId}`,
    data,
    token
  );
};

export const addUserService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/user/users`, data, token);
};

export const signupUserService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/signup`,
      data,
    });

    return res.data;
  } catch (err: any) {
    return err;
  }
};

export const deleteUserService = async (UserId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/user/users${UserId}`, token);
};
