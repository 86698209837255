import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getCustomersService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/customer${query}`, token);
};

export const getTotalCreditService = async (query: string, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/customer/total-credit${query}`,
    token
  );
};

export const searchCustomerService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/customer/search${query}`, token);
};

export const updateCustomerService = async (
  customerId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/customer${customerId}`,
    data,
    token
  );
};

export const addCustomerService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/customer`, data, token);
};

export const deleteCustomerService = async (
  customerId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/customer${customerId}`, token);
};

export const increaseCustomerCreditService = async (
  customerId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/customer/increase-credit/${customerId}`,
    data,
    token
  );
};