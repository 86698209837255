import { List } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import DashboardIcon from "@mui/icons-material/Dashboard";
import styles from "./index.module.scss";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import NavItem from "./NavItem";
import { useTranslation } from "../i18n";

const NavMenu = (props: any) => {
  const location = useLocation();
  const { t } = useTranslation();
  let index = -1;

  const generalNavItems = [
    {
      label: t("dashboard"),
      icon: <DashboardIcon style={{ color: "currentColor" }} />,
      path: "/",
    },
    {
      label: t("purchase"),
      icon: <PeopleAltIcon style={{ color: "currentColor" }} />,
      path: "/purchases",
    },
    {
      label: t("sales"),
      icon: <RecentActorsIcon style={{ color: "currentColor" }} />,
      path: "/sales",
    },
    {
      label: t("stock"),
      icon: <MoneyOffIcon style={{ color: "currentColor" }} />,
      path: "/products",
    },
    // {
    //   label: t("branches"),
    //   icon: <RecentActorsIcon style={{ color: "currentColor" }} />,
    //   path: "/branches",
    // },
  ];

  return (
    <>
      <List>
        {generalNavItems.map((nav) => {
          index++;
          return (
            <Link
              key={index}
              to={nav.path}
              className={styles.link}
              onClick={() => {}}
            >
              <NavItem nav={nav} selected={nav.path === location.pathname} />
            </Link>
          );
        })}
      </List>
    </>
  );
};

export default NavMenu;
