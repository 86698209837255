import { TextField } from '@mui/material';
import styles from '../index.module.scss';

const FormDatePicker = (props: any) => {
  return (
    <TextField
      id="date"
      type="date"
      inputRef={props?.reference}
      className={styles.dateInput}
      classes={{ root: styles.dateInputRoot }}
      value={props?.element?.value}
      onChange={(e) => {
        props?.element?.onChange(e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default FormDatePicker;
