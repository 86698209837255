const translationsKin = {
  welcome: "Ikaze",
  forms: "Amafishi",
  dashboard: "Imbonerahamwe",
  staff: "Abakozi",
  search: "Shakisha",
  exportExcel: "Excel",
  months: "Amezi",
  logout: "Gusohoka",
  report: "Raporo",
  addNew: "Ongeramo",
  amount: "Amafaranga",
  paidAmount: "Amafaranga yishyuwe",
  firstName: "Izina Ry'irikrisitu",
  lastName: "Izina Ry'umuryango",
  name: "Izina",
  nationalID: "Indangamuntu",
  email: "Imeli",
  position: "Icyo Akora",
  gender: "Igitsina",
  dob: "Itariki y'amavuko",
  action: "Ibindi",
  delete: "Siba",
  view: "Reba",
  update: "Hindura",
  cancel: "Hagarika",
  confirmDelete: "Emeza Gusiba",
  confirmDeleteDesc: "Urahamya ko ushaka gusiba",
  deleteSuccess: "basibwe neza!",
  male: "Gabo",
  female: "Gore",
  addStaff: "Kwandika Umukozi",
  updateStaff: "Hindura Umwirondoro w'umukozi",
  save: "Emeza",
  beginingDate: "Itariki yatangiriye akazi",
  filter: "Hitamo",
  createdBy: "Yinjijwe Na",
  dateOfBirth: "Itariki y'amavuko",
  dateOfMaturing: "Itariki yo gucuka",
  createdAt: "Igihe Yinjijwe",
  updatedAt: "Ubwo Iheruka Guhindurwa",
  status: "Icyiciro",
  category: "Icyiciro",
  work: "Akazi",
  documentName: "Izina ry'ifishi",
  documentType: "Ubwoko bw'ifishi",
  document: "Ifishi",
  add: "Andika",
  days: "Iminsi",
  client: "Umukiriya",
  paid: "Amafaranga yishyuwe",
  cost: "Igiciro",
  addSale: "Gucuruza itungo",
  kgUnitCost: "Igiciro cy'ikiro kimwe",
  totalPrice: "Total",
  remaining: "Izisigaye",
  afterNoon: "Umugoroba",
  morning: "Igitondo",
  L: "Litiro",
  Kg: "Kilogram",
  m: "Metero",
  expense: "Depanse",
  paymentMethod: "Uburyo bwo kwishyura",
  sale: "Kugurisha Itungo",
  dontHaveAccountDesc: "Nta konti ufite? Kanda hano uyifunguze",
  forgotPassword: "Wibagiwe Password?",
  rememberMe: "Nyibuka ubutaha",
  purchase: "Kurangura",
  sales: "Gucuruza",
  stock: "Stock",
  settings: "Settings",
  items: "Ibicuruzwa",
  profile: "Konti Yanjye",
  language: "Ururimi",
  createAccount: "Funguza Konti",
  accountCreated: "Konti yafunguwe neza",
  incorrectCredentials: "Mwanditse password cyangwa email itariyo",
  totalSales: "Ayacurujwe",
  stockValue: "Agaciro k'iduka",
  debts: "Amadeni ufite",
  credits: "Ideni bakurimo",
  totalIncome: "Inyungu",
  topSellingProducts: "Ibicuruzwa cyane",
  customers: "Abakiriya",
  supplier: "Abaranguza",
  products: "products",
  users: "abakozi",
  profit: "Inyungu",
  purchases: "Ibyaranguwe",
  addPurchase: "Ongeramo Kurangura",
};

export default translationsKin;
