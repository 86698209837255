import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getSuppliersService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/supplier${query}`, token);
};

export const getTotalDebtService = async (query: string, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/supplier/total-debt${query}`,
    token
  );
};

export const searchSupplierService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/supplier/search${query}`, token);
};

export const updateSupplierService = async (
  supplierId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/supplier${supplierId}`,
    data,
    token
  );
};

export const addSupplierService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/supplier`, data, token);
};

export const deleteSupplierService = async (
  supplierId: string,
  token: string
) => {
  return await HttpRequest.delete(`${SERVER_URL}/supplier${supplierId}`, token);
};

export const increaseSupplierDebtService = async (
  supplierId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/supplier/increase-debt/${supplierId}`,
    data,
    token
  );
};
