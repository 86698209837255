import "../../components/form_styles.css";
import { useTranslation } from "../../i18n";
import { appActions } from "../../store/app";
import { useDispatch } from "react-redux";
import GenericForm from "../../components/GenericForm";
import { addAction } from "../../store/app/appActions";
import { addSupplierService } from "../../store/app/supplier/supplierService";
import Storage from "../../utils/storage";
import { Form } from "antd";
import { addUserService } from "../../store/app/user/userService";

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  onSubmit: Function;
  styles?: string;
  confirmText?: string;
}

const NewUser = (props: GenericFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    values.company = Storage.getUser()?.company?._id;
    dispatch(
      addAction(
        addUserService,
        values,
        token,
        () => {},
        () => {
          props.close();
          dispatch(appActions.setFetchedUsers(false));
        }
      )
    );
  };

  const phoneValidator = (_: any, value: string) => {
    if (!value || /^(\+?\d+)?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid phone number");
  };

  const formElements = [
    {
      name: "names",
      label: "Names",
      type: "text",
      rules: [{ required: true, message: "Please input your username!" }],
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      rules: [{ validator: phoneValidator }],
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      rules: [{ type: "email", message: "The input is not a valid email!" }],
    },
    { name: "password", label: "Password", type: "password" },
    { name: "Role", label: "Role", type: "text" },
  ];

  return (
    <GenericForm
      open={props.open}
      close={() => props.close()}
      title="New User"
      styles="vertical"
      form={form}
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default NewUser;
