import "../../../components/form_styles.css";
import { useTranslation } from "../../../i18n";
import { AppState, appActions } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../../components/GenericForm";
import { addAction } from "../../../store/app/appActions";
import Storage from "../../../utils/storage";
import { addCustomerService } from "../../../store/app/customer/customerService";
import { Form } from "antd";

const NewCustomer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const appState: AppState = useSelector((state: any) => state.app);

  const onFinish = (values: any) => {
    values.company = Storage.getUser()?.company?._id;

    dispatch(
      addAction(
        addCustomerService,
        values,
        token,
        () => {},
        () => dispatch(appActions.setFetchedCustomers(false))
      )
    );
  };

  const phoneValidator = (_: any, value: string) => {
    if (!value || /^(\+?\d+)?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid phone number");
  };

  const formElements = [
    {
      name: "name",
      label: "Name",
      type: "text",
      rules: [{ required: true, message: "Please input your username!" }],
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      rules: [{ type: "email", message: "The input is not a valid email!" }],
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      rules: [{ validator: phoneValidator }],
    },
    { name: "address", label: "Address", type: "text" },
    { name: "tinNumber", label: "Tin Number", type: "text" },
  ];

  return (
    <GenericForm
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.closeForm());
      }}
      form={form}
      title="New Customer"
      styles="horizontal"
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default NewCustomer;
