const FileInput = (props: any) => {
  return (
    <input
      type="file"
      id="avatar"
      name="avatar"
      onChange={props.element.onChange}
      accept="image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"
    />
  );
};

export default FileInput;
