import { createSlice } from "@reduxjs/toolkit";
import { Purchase } from "../../interfaces/purchase";
import { Product } from "../../interfaces/product/product";
import { Supplier } from "../../interfaces/supplier";

export interface PurchasesState {
  purchases: Purchase[];
  fetchedPurchases: boolean;
  fetchedFormElements: boolean;
  fetchingFormElements: boolean;
  count: number;
  suppliers: Supplier[];
}

const initialState: PurchasesState = {
  purchases: [],
  fetchedPurchases: false,
  fetchedFormElements: false,
  fetchingFormElements: false,
  count: 0,
  suppliers: [],
};

const purchasesSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setPurchases(state, action) {
      state.purchases = action.payload.purchases;
      state.count = action.payload.count;
      state.fetchedPurchases = action.payload.fetched;
    },
    setPurchasesCount(state, action) {
      state.count = action.payload;
    },
    setFetchedPurchases(state, action) {
      state.fetchedPurchases = action.payload;
    },
    setFetchedFormElements(state, action) {
      state.fetchedFormElements = action.payload;
    },
    setFetchingFormElements(state, action) {
      state.fetchingFormElements = action.payload;
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    resetPurchaseState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const purchasesActions = purchasesSlice.actions;

const purchasesReducer = purchasesSlice.reducer;

export default purchasesReducer;
