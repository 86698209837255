const translationsSw = {
  welcome: "Karibu",
  forms: "Fomu",
  dashboard: "Dashibodi",
  staff: "Wafanyakazi",
  search: "Tafuta",
  exportExcel: "Eksporti Faili",
  months: "Miezi",
  sale: "Uuzaji",
  logout: "Toka",
  report: "Ripoti",
  client: "Mteja",
  paid: "Kiasi Kilicholipwa",
  addSale: "Ongeza Uuzaji",
  kgUnitCost: "Gharama kwa Kilo",
  totalPrice: "Jumla",
  addNew: "Ongeza Mpya",
  firstName: "Jina la Kwanza",
  lastName: "Jina la Mwisho",
  name: "Jina",
  nationalID: "Kitambulisho cha Kitaifa",
  email: "Barua pepe",
  position: "Nafasi",
  gender: "Jinsia",
  dob: "Tarehe ya Kuzaliwa",
  category: "Jamii",
  action: "Hatua",
  delete: "Futa",
  view: "Angalia",
  update: "Sasisha",
  cancel: "Ghairi",
  confirmDelete: "Thibitisha Kufuta",
  confirmDeleteDesc: "Je, uko tayari kufuta",
  deleteSuccess: "Imefutwa kwa Mafanikio!",
  male: "Kiume",
  female: "Kike",
  addStaff: "Ongeza Wafanyakazi",
  updateStaff: "Sasisha Wafanyakazi",
  save: "Hifadhi",
  beginningDate: "Tarehe ya Kuanza",
  filter: "Chuja",
  amount: "Kiasi",
  paidAmount: "Kiasi Kilicholipwa",
  createdBy: "Imetengenezwa na",
  createdAt: "Imetengenezwa Tarehe",
  updatedAt: "Sasishwa Tarehe",
  dateOfBirth: "Tarehe ya Kuzaliwa",
  status: "Hali",
  photo: "Picha",
  work: "Kazi",
  documentName: "Jina la Nyaraka",
  documentType: "Aina ya Nyaraka",
  document: "Nyaraka",
  add: "Ongeza",
  days: "Siku",
  cost: "Gharama",
  remaining: "Inayosalia",
  afterNoon: "Baada ya Mchana",
  morning: "Asubuhi",
  L: "Lita",
  Kg: "Kilogramu",
  m: "Mita",
  expense: "Matumizi",
  paymentMethod: "Njia ya Malipo",
  dontHaveAccountDesc: "Huna akaunti? Bonyeza Hapa kujiandikisha",
  forgotPassword: "Umesahau Nenosiri?",
  rememberMe: "Nikumbuke",
  purchase: "Ununuzi",
  sales: "Uuzaji",
  stock: "Hisabati",
  settings: "Mipangilio",
  items: "Vitu",
  profile: "Profaili",
  language: " Lugha",
  createAccount: "Tengeneza Akaunti",
  accountCreated: "Akaunti imeundwa kwa Mafanikio!",
  incorrectCredentials: "Barua pepe au Nenosiri Sio Sahihi",
  totalSales: "Jumla ya Uuzaji",
  stockValue: "Thamani ya Hisabati",
  debts: "Madeni",
  credits: "Mikopo",
  totalIncome: "Jumla ya Mapato",
  topSellingProducts: "Bidhaa Zinazouzwa Zaidi",
  customers: "Wateja",
  supplier: "Wasambazaji",
  products: "Bidhaa",
  users: "Watumiaji",
  profit: "Faida",
};

export default translationsSw;
