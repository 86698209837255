import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getShopsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop${query}`, token);
};

export const searchShopService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/search${query}`, token);
};

export const updateShopService = async (
  shopId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/one/${shopId}`, data, token);
};

export const addShopService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop`, data, token);
};

export const deleteShopService = async (shopId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/one/${shopId}`, token);
};
