import * as React from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Steps,
  Typography,
} from "antd";
import dayjs from "dayjs";
import Storage from "../../utils/storage";
import { useEffect, useState } from "react";
import styles from "../../components/index.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, Grid, Skeleton } from "@mui/material";
import { AppState, appActions } from "../../store/app";
import { LayoutType, layout } from "../../components/GenericForm";
import { Product } from "../../interfaces/product/product";
import { ProductItem } from "../../interfaces/product/productItem";
import SalesReceipt from "../../components/SalesReceipt";
import { Sale } from "../../interfaces/sales/sale";
import {
  addSalesService,
  addToSalesListService,
  updateSalesService,
} from "../../store/sales/salesService";
import { SalesState, salesActions } from "../../store/sales";
import { fetchElementsAction } from "../../store/sales/salesActions";
import CustomLoadingButton from "../../components/CustomLoadingButton";
import { increaseCustomerCreditService } from "../../store/app/customer/customerService";
import { ShopState } from "../../store/shop";
import { ProductUnit } from "../../interfaces/product/productUnit";
import { ProductState } from "../../store/products";
import {
  getProductItemsAction,
  getProductsAction,
} from "../../store/products/product/productActions";
import { ProductPrice } from "../../interfaces/product/productPrice";

export interface NewPurchaseItem {
  product: string;
  productItem: string;
  productItemName: string;
  quantity: number;
  totalPrice: number;
  unit: string;
  unitPrice: string;
}

export default function NewSale(props: any) {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [sale, setSale] = useState<Sale>();
  const [productItems, setProductItems] = useState<ProductItem[]>([]);
  const [productUnits, setproductUnits] = useState<any>([]);
  const [newPurchaseItems, setNewPurchaseItems] = useState<NewPurchaseItem[]>(
    []
  );
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const currentDate = dayjs();
  const [form] = Form.useForm();
  // const [purchasesItems, setPurchasesItems] = useState([] as NewPurchaseData[]);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const salesState: SalesState = useSelector((state: any) => state.sales);
  const appState: AppState = useSelector((state: any) => state.app);
  const productState: ProductState = useSelector((state: any) => state.product);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const { Option } = Select;

  const resetFields = (allFields: Boolean = false) => {
    form.resetFields([
      "product",
      "productItem",
      "purchasePrice",
      "quantity",
      "unit",
      "unitPrice",
      "totalPrice",
    ]);
  };

  useEffect(() => {
    if (!salesState.fetchedFormElements) {
      dispatch(fetchElementsAction(``, token));
    }
    if (!productState.fetchedProducts) {
      dispatch(getProductsAction(``, token));
    }

    if (!productState.fetchedProductItems) {
      dispatch(getProductItemsAction(``, token));
    }

    return () => {
      resetFields(true);
    };
  }, []);

  const addSaleHandler = async () => {
    setLoading(true);
    if (sale?._id !== undefined) {
      const res = await updateSalesService(
        sale?._id,
        {
          status: "Completed",
          amountPaid: [{ value: form.getFieldValue("amountPaid") }],
          date: form.getFieldValue("date"),
          isCredit:
            +form.getFieldValue("amountPaid") < +form.getFieldValue("total"),
          customer: form.getFieldValue("customer"),
        },
        token
      );
      console.log("done submitting");
    }

    if (+form.getFieldValue("amountPaid") < +form.getFieldValue("total")) {
      await increaseCustomerCreditService(
        form.getFieldValue("customer"),
        {
          amount:
            +form.getFieldValue("total") - +form.getFieldValue("amountPaid"),
        },
        token
      );
    }
    setLoading(false);

    //DISPLAY SUCCESS MESSAGE TOAST
    dispatch(
      appActions.setHttpResponse({
        statusCode: 200,
        status: "success",
        message: "Sale added Successfully!",
      })
    );
    dispatch(appActions.resetTotal());

    // NAVIGATE
    dispatch(salesActions.setFetchedSales(false));
    props.close();
  };

  const validateSoldQuantity = (_: any, value: string) => {
    if (parseFloat(value) === 0) {
      return Promise.reject("Quantity sold must be greater than 0");
    } else if (parseFloat(value) > +form.getFieldValue("stock")) {
      return Promise.reject("Quantity sold exceeding available stock");
    }
    return Promise.resolve();
  };

  const onProductItemChanged = (value: any) => {
    let price: ProductPrice | undefined = productState?.productPrices.find(
      (currentItem: ProductPrice) =>
        currentItem?.productItem?._id === value &&
        currentItem.type === "sellingPrice"
    );
    form.setFieldValue("unitPrice", price?.value ?? 0);
  };

  const getProductItems = (productId: string) => {
    const selectedProductItems = productState?.productItems.filter(
      (productItem: ProductItem) => productItem?.product?._id === productId
    );
    const selectedUnits = productState?.productUnits.filter(
      (productUnit: ProductUnit) => productUnit?.product === productId
    );
    setProductItems(selectedProductItems);
    setproductUnits(selectedUnits[0]?.formular);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    if (productUnits !== undefined) {
      let unit = productUnits.filter(
        (productUnit: any) => productUnit._id === values.unit
      );
      values.unit = unit[0].label;
    }

    let item = productItems.filter(
      (productItem: any) => productItem._id === values.productItem
    );
    values.productItemName = item[0].name;
    values.shop = shopState.shop?._id;
    values.company = Storage.getUser()?.company?._id;
    if (newPurchaseItems.length === 0) {
      let sale = await addSalesService(
        {
          shop: shopState.shop?._id,
          company: Storage.getUser()?.company?._id,
        },
        token
      );

      setSale(sale.data);
      values.sales = sale.data._id;
      await addToSalesListService(sale.data._id, values, token);
    }
    setNewPurchaseItems([...newPurchaseItems, values]);
    dispatch(appActions.addToTotal(values.totalPrice));
    if (sale?._id) {
      values.sales = sale?._id;
      let data = await addToSalesListService(sale?._id, values, token);
    }
    setLoading(false);
    resetFields();
  };

  const formElements = [
    {
      name: "product",
      label: "Product",
      type: "select",
      loading: productState?.fetchingProducts,
      options: productState?.products,
      selectLabel: "productName",
      handleSelectChange: async (value: any) => {
        let products = productState?.products.filter(
          (product: Product) => product._id === value
        );
        getProductItems(products[0]._id);
      },
    },
    {
      name: "productItem",
      label: "Item",
      type: "select",
      loading: productState.fetchingProductItems,
      options: productItems,
      handleSelectChange: onProductItemChanged,
      rules: [{ required: true, message: "Item is required" }],
    },
    {
      name: "unit",
      label: "Unit",
      type: "select",
      options: productUnits,
      selectLabel: "label",
      selectValue: "label",
    },
    // {
    //   name: "purchasePrice",
    //   label: "Purchase Price",
    //   type: "number",
    // },
    {
      name: "unitPrice",
      label: "Unit Price",
      type: "number",
      rules: [
        { required: true, message: "Please enter the unit buying Price" },
      ],
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue("unitPrice"),
        });
      },
      rules: [
        { required: true, message: "Please enter the quantity sold" },
        { validator: validateSoldQuantity },
      ],
    },
    {
      name: "totalPrice",
      label: "Total Price",
      type: "number",
      onChange: (value: any) => {
        form.setFieldsValue({
          unitPrice: +value / +form.getFieldValue("quantity"),
          totalPaid: +value,
        });
      },
    },
  ];

  const secondFormElements = [
    {
      name: "total",
      label: "Total Price",
      type: "number",
      disabled: true,
      rules: [{ required: true, message: "Please enter the amount received" }],
    },
    {
      name: "amountPaid",
      label: "Amount Received",
      type: "number",
      rules: [{ required: true, message: "Please enter the amount received" }],
    },
    {
      name: "customer",
      label: "Customer",
      type: "select",
      options: salesState?.customers,
      rules: [{ required: true, message: "Please enter the customer" }],
      onChange: () => {
        console.log();
      },
    },
    {
      name: "date",
      label: "Start Date",
      type: "date",
    },
  ];

  return (
    <div>
      <div className="flex items-center ms-10 mt-8">
        <div
          className="bg-slate-300 p-3 rounded-md me-4 cursor-pointer"
          onClick={() => {
            dispatch(appActions.resetTotal());
            props.close();
          }}
        >
          <ArrowBackIcon />
        </div>
        <Typography className="font-bold text-xl">Add New Sales</Typography>
      </div>
      <Grid container className="mt-8">
        <Grid item xs={8}>
          <Box className={styles.genericForm_column}>
            <div className="bg-white pt-4 rounded-md">
              <div className="w-1/4 mx-auto">
                <Steps
                  direction="horizontal"
                  current={currentStep}
                  items={[{}, {}]}
                />
              </div>
              <Form
                {...layout}
                layout={formLayout}
                form={form}
                name="control-hooks"
                onFinish={onSubmit}
                className="my_form"
              >
                <Box style={{ margin: "0 auto" }}>
                  {currentStep === 0 &&
                    formElements.map((element: any) => (
                      <Box
                        key={element.name}
                        className={styles.forms_input_horizontal}
                      >
                        <Form.Item
                          name={element.name}
                          label={element.label}
                          rules={element.rules ? element.rules : []}
                        >
                          {element.type === "number" && (
                            <InputNumber
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === "text" && (
                            <Input disabled={element?.disabled ?? false} />
                          )}
                          {element.type === "date" && (
                            <DatePicker defaultValue={currentDate} />
                          )}
                          {element.type === "select" && (
                            <>
                              {element?.loading !== true && (
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={element?.handleSelectChange}
                                  filterOption={(input, option) =>
                                    (option?.children?.toString() as string)
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {element?.options?.map((opt: any) => (
                                    <Option key={opt._id} value={opt._id}>
                                      {opt.name ??
                                        opt[`${element.selectLabel}`]}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                              {element?.loading === true && (
                                <Skeleton
                                  className={`${styles.form_input} ${styles.form_select_skeleton}`}
                                />
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                  {currentStep === 1 &&
                    secondFormElements.map((element: any) => (
                      <Box
                        key={element.name}
                        className={styles.forms_input_horizontal}
                      >
                        <Form.Item
                          name={element.name}
                          label={element.label}
                          rules={element.rules ? element.rules : []}
                        >
                          {element.type === "number" && (
                            <InputNumber
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === "text" && (
                            <Input disabled={element?.disabled ?? false} />
                          )}
                          {element.type === "date" && (
                            <DatePicker defaultValue={currentDate} />
                          )}
                          {element.type === "select" && (
                            <>
                              {element?.loading !== true && (
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={element?.handleSelectChange}
                                  filterOption={(input, option) =>
                                    (option?.children?.toString() as string)
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {element?.options?.map((opt: any) => (
                                    <Option key={opt._id} value={opt._id}>
                                      {opt.name ??
                                        opt[`${element.selectLabel}`]}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                              {element?.loading === true && (
                                <Skeleton
                                  className={`${styles.form_input} ${styles.form_select_skeleton}`}
                                />
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                </Box>
                {currentStep === 0 && (
                  <Box className={styles.center}>
                    <CustomLoadingButton loading={loading} text="ADD" />
                    <Button
                      className={styles.confirmButton}
                      variant="contained"
                      onClick={() => {
                        form.setFieldValue("total", appState.total);
                        form.setFieldValue("amountPaid", appState.total);
                        setCurrentStep(1);
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                )}
                {currentStep === 1 && (
                  <Box className={styles.center}>
                    <Button
                      className={styles.confirmButton}
                      variant="contained"
                      onClick={() => addSaleHandler()}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "white",
                            height: "1rem",
                            width: "1rem",
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                )}
              </Form>
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <SalesReceipt
            title="Sales"
            items={newPurchaseItems}
            onSave={addSaleHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
}
