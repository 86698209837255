import {
  Grid,
  Typography,
  Box,
  Divider,
  Avatar,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import store from "store";
import styles from "../index.module.scss";
import FormInputs from "../../components/inputs/FormInputs";
import { User } from "../../interfaces/user";
import EditIcon from "@mui/icons-material/Edit";
import Storage from "../../utils/storage";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import UploadImage from "../../components/UploadImage";
import { setProfilePictureService } from "../../store/app/user/userService";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { setResponseAction } from "../../utils/functions";
import { successToast } from "../../store/app/appActions";

const ProfileSettings = () => {
  const user: User = Storage.getUser();
  const token = Storage.getToken();
  const [activeTab, setActiveTab] = useState(0);
  const [uploading, setUploading] = useState(false);
  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const [uploadPicture, setUploadPicture] = useState(false);

  const uploadProfilePictureHandler = async (data: any) => {
    try {
      setUploading(true);
      const res = await setProfilePictureService(user._id, data, token);
      setUploading(false);
      if(res.status === 200) {
        var newUser : User = { ...user, image: res.data  };
        store.set('user', newUser);
        dispatch(authActions.setUser(newUser));
        dispatch(successToast("Profile set successfully!"));
        setUploadPicture(false);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const userAccountElements = [
    {
      label: "User Name",
      identifier: "username",
      inputComponent: "input",
      type: "text",
      value: user?.names,
      placeholder: "Your Last Name",
    },
    {
      label: "Phone Number",
      identifier: "contact",
      inputComponent: "input",
      type: "text",
      value: user?.phone,
      placeholder: "Contact",
    },
    {
      label: "E-mail",
      identifier: "email",
      inputComponent: "input",
      value: user?.email,
      placeholder: "Enter Your email",
    },
    {
      label: "Role",
      identifier: "role",
      inputComponent: "input",
      value: user?.role,
      disabled: true,
    },
  ];

  const changePasswordElements = [
    {
      label: "Old Password",
      identifier: "username",
      inputComponent: "input",
      type: "text",
      placeholder: "Your Last Name",
    },
    {
      label: "New Password",
      identifier: "contact",
      inputComponent: "input",
      type: "text",
      placeholder: "Contact",
    },
    {
      label: "Confirm New Password",
      identifier: "email",
      inputComponent: "input",
      placeholder: "Enter Your email",
    },
  ];

  return (
    <>
      <Box className={styles.profileContainer}>
        <Typography className={styles.profileTitle}>Account</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box className={styles.profilePhotoCard}>
              <Avatar
                style={{
                  height: "5rem",
                  width: "5rem",
                }}
              >
                <img
                  className={styles.profilePicture}
                  src={user?.image?.url}
                  alt="profile"
                />
              </Avatar>
              <Typography className={styles.profileName}>
                {user?.names}
              </Typography>
              <Typography className={styles.profileSecondaryText}>
                {user?.email}
              </Typography>
              <Typography className={styles.profileSecondaryText}>
                {user?.phone}
              </Typography>
              <Divider />
              <Divider />
              <Button
                className={styles.uploadImage}
                onClick={() => setUploadPicture(true)}
              >
                Upload Image
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className="bg-white  rounded-lg">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Profile" />
                <Tab label="CHANGE PASSWORD" />
              </Tabs>
              <Box className={styles.profileLeftSection}>
                {activeTab === 0 && (
                  <div className="">
                    <Box className={styles.profileTopSection}>
                      <Typography variant="h6">Profile</Typography>
                      <Typography className={styles.profileSecondaryText}>
                        The information can be edited
                      </Typography>
                    </Box>
                    <Divider style={{ marginBottom: "2rem" }} />
                    <Box className={styles.genericForm_column}>
                      {userAccountElements.map(
                        (element: any, index: number) => {
                          return (
                            <FormInputs
                              key={index}
                              element={element}
                              style="vertical"
                            />
                          );
                        }
                      )}
                    </Box>

                    <Divider style={{ marginTop: "0rem" }} />
                    <Box className={`${styles.right} ${styles.mg1Vertical}`}>
                      <Button
                        startIcon={<SaveIcon />}
                        className={styles.genericButton}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Box>
                  </div>
                )}
                {activeTab === 1 && (
                  <>
                    <Typography></Typography>
                    <Box className={`${styles.changePasswordContainer}`}>
                      {changePasswordElements.map(
                        (element: any, index: number) => {
                          return (
                            <FormInputs
                              key={index}
                              element={element}
                              style="vertical"
                            />
                          );
                        }
                      )}

                      <Box className={styles.center}>
                        <Button
                          sx={{ marginTop: "2rem" }}
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          Change
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
      {uploadPicture && (
        <UploadImage
          open={uploadPicture}
          close={() => setUploadPicture(false)}
          title="Set Profile Picture"
          uploading={uploading}
          uploadHandler={uploadProfilePictureHandler}
        />
      )}
    </>
  );
};

export default ProfileSettings;
