import { Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { useState } from "react";
import Customers from "./customers/customers";
import SalesPage from "./SalesPage";

const SalesMain = () => {
  const [activeTab, setActiveTab] = useState(0);
  const pages = ["Sales", "Customers"];

  return (
    <Layout pages={pages} activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 0 && <SalesPage />}
      {activeTab === 1 && <Customers />}
    </Layout>
  );
};

export default SalesMain;
