import { Form, Input } from "antd";
import styles from "../index.module.scss";
import phone_otp from "../../../assets/images/phone_otp.png"; 
import logo from "../../../assets/images/logo-final.png";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const SendOTP = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");

  const submitOTP = (values: any) => {
    console.log("Valuesss ", values);
  };

  return (
    <div className={styles.authBackground}>
      <div className={styles.authCard}>
        <div className={styles.authEllipse}></div>
        <div className={styles.authCardContent}>
          <div className="w-full border-r border-5 border-gray-600 text-center flex items-center justify-center flex-col">
            <div className="text-blue-800 font-bold text-3xl text-center">
              OTP Verification
            </div>
            <div className="text-sm w-2/3 mb-6">
              We will send you one time password on mobile number you’re going
              to enter here.
            </div>
            <img style={{ height: "10rem" }} alt="otp" src={phone_otp} />
            <Form layout={formLayout} form={form} onFinish={submitOTP} style={{width: "75%", marginTop: "1rem"}}>
              <Form.Item
                name="phone"
                label="Enter Your Mobile Number"
                rules={[
                  { required: true, message: "Phone Number is required!" },
                ]}
              >
                <Input placeholder="example+250 781254339" />
              </Form.Item>
              <LoadingButton
                variant="contained"
                type="submit"
                style={{ width: "100%" }}
              >
                SEND OTP
              </LoadingButton>
            </Form>
          </div>
          <div className="w-full flex items-center justify-center">
            <img style={{ height: "10rem" }} alt="otp" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendOTP;
