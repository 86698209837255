import { Box, Button, Grid, Menu, MenuItem } from "@mui/material";
import Layout from "../../../components/Layout";
import styles from "../../index.module.scss";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { AppState, appActions } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../i18n";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Storage from "../../../utils/storage";
import SellIcon from "@mui/icons-material/Sell";
import { Typography } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewPriceList from "./NewProductItem";
import { getStockAction } from "../../../store/products/productItem/productItemActions";
import {
  ProductItem,
  ProductItemRow,
} from "../../../interfaces/product/productItem";
import { initials, renderMoney } from "../../../utils/functions";
import UpdatePriceList from "./UpdatePriceList";
import { Product } from "../../../interfaces/product/product";
import NoData from "../../../components/NoData";
import { ProductState, productActions } from "../../../store/products";
import { Stock } from "../../../interfaces/stock";
import { ShopState } from "../../../store/shop";
import ProductCardSkeleton from "./ProductCardSkeleton";
import { getProductItemsAction } from "../../../store/products/product/productActions";

const ProductItems = () => {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [priceList, setPriceList] = useState<ProductItem>();
  const [update, setUpdate] = useState(false);
  const [getAll, setGetAll] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const appState: AppState = useSelector((state: any) => state.app);
  const productState: ProductState = useSelector((state: any) => state.product);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const exportExcelHandler = () => {};
  const [activeTab, setActiveTab] = useState(0);
  const pages = ["Products", "Overview"];
  const navigate = useNavigate();

  useEffect(() => {
    if (getAll) {
      if (shopState.shop?._id) {
        dispatch(
          getProductItemsAction(`?product=${productState.product._id}`, token)
        );
      } else {
        dispatch(
          getProductItemsAction(
            `/all/${Storage.getUser()?.company?._id}?product=${
              productState.product._id
            }`,
            token
          )
        );
      }
    } else {
      if (shopState.shop?._id) {
        dispatch(
          getStockAction(
            `?product=${productState.product._id}&shop=${shopState.shop?._id}`,
            token
          )
        );
      } else {
        dispatch(
          getStockAction(
            `/all/${Storage.getUser()?.company?._id}?product=${
              productState.product._id
            }`,
            token
          )
        );
      }
    }
  }, [productState.product._id, getAll]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(productActions.setFetchedProductItems(false));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(productActions.setFetchedProductItems(false));
  };

  const createData = (priceList: ProductItem): ProductItemRow => {
    return {
      date: priceList?.createdAt.substring(0, 10),
      description: priceList?.name,
      price: renderMoney(priceList.prices[0]?.value),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setPriceList(priceList);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  // const rows = productState.productItems?.map((priceList: ProductItem) =>
  //   createData(priceList)
  // );

  return (
    <Layout pages={pages} activeTab={activeTab} setActiveTab={setActiveTab}>
      <Box className={styles.searchComponent}>
        <div className="flex items-center">
          <ArrowCircleLeftOutlinedIcon
            onClick={() => {
              dispatch(productActions.setFetchedProductItems(false));
              navigate("/products");
            }}
            className="cursor-pointer"
          />
          <Typography className="font-bold text-xl ms-2">
            {`Products / ${productState.product.name}`}
          </Typography>
        </div>
        <div>
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportExcelHandler}
            className={styles.exportExcelButton}
          >
            {t("exportExcel")}
          </LoadingButton>
          <Button
            className={styles.primaryButton}
            startIcon={<AddCircleOutlinedIcon />}
            variant="contained"
            onClick={() => {
              dispatch(appActions.setOpenForm({ open: true, action: "add" }));
            }}
          >
            Add Product Item
          </Button>
        </div>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="bg-white rounded-lg mt-2 overflow-hidden">
            <div className="flex items-center">
              <div className="flex basis-10/12  border rounded-lg m-4 p-1">
                <SearchIcon className="me-3" />
                <input
                  placeholder="Search Products"
                  className="w-full outline-none"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setsearchText(event.target.value);
                  }}
                />
              </div>
              <FilterListIcon
                onClick={() => {
                  setGetAll(!getAll);
                }}
                style={{
                  cursor: "pointer",
                  marginRight: "2rem",
                  backgroundColor: getAll ? "#c9c9c9" : "transparent",
                }}
              />
            </div>
            <hr />
            <div className="h-[72vh] overflow-y-scroll">
              {productState?.products?.length > 0 &&
                productState?.products?.map((product: Product) => (
                  <div
                    key={product._id}
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(productActions.setProduct(product));
                      dispatch(productActions.setFetchedProductItems(false));
                    }}
                  >
                    <div className="h-20 flex items-center px-4 py-2 border-b border-gray-200">
                      {!product?.image?.url && (
                        <div className="bg-gray-200 rounded-lg border-b h-12 w-12 flex justify-center items-center me-2">
                          <Typography className="whitespace-nowrap font-semibold text-2xl text-gray-500">
                            {initials(product?.name)}
                          </Typography>
                        </div>
                      )}
                      {product?.image?.url && (
                        <img
                          className="h-12 w-12"
                          src={product.image?.url}
                          alt="pff"
                        />
                      )}
                      <div>
                        <div>{product.name}</div>
                      </div>
                    </div>
                  </div>
                  // <ProductCard key={product._id} product={product} />
                ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="mt-2 h-[76vh] overflow-y-scroll">
            <div className={styles.productsContainer}>
              {appState.isFetching && <ProductCardSkeleton />}
              {!appState.isFetching && (
                <>
                  {getAll && (
                    <>
                      {productState.productItems?.length === 0 && <NoData />}
                      {productState.productItems?.length > 0 &&
                        productState.productItems?.map(
                          (productItem: ProductItem) => (
                            <Link
                              key={productItem?._id}
                              to={`/products/productitem/${productItem?._id}`}
                              onClick={() =>
                                dispatch(
                                  productActions.setStockItem(productItem)
                                )
                              }
                              className="border bg-white w-52 mx-2 mb-3 cursor-pointer rounded-lg"
                            >
                              <div className="h-32 flex items-center justify-center">
                                {!productState?.product?.image?.url && (
                                  <div className="bg-gray-200 rounded-md h-24 w-32 flex justify-center items-center me-2">
                                    <Typography className="whitespace-nowrap font-semibold text-2xl text-gray-500">
                                      {initials(productItem?.name)}
                                    </Typography>
                                  </div>
                                )}
                                {productState?.product?.image?.url && (
                                  <img
                                    className="h-24 w-32"
                                    src={productState?.product?.image?.url}
                                    alt="pff"
                                  />
                                )}
                              </div>
                              <hr />
                              <div className="font-semibold mt-2 mb-4 mx-2 whitespace-nowrap truncate">
                                {productItem.name}
                              </div>
                              <div className="flex justify-end text-gray-400 mb-2">
                                <NoteAltOutlinedIcon />
                                <DeleteOutlineOutlinedIcon />
                              </div>
                            </Link>
                          )
                        )}
                    </>
                  )}

                  {!getAll && (
                    <>
                      {productState.stock?.length === 0 && <NoData />}
                      {productState.stock?.length > 0 &&
                        productState.stock?.map((productItem: Stock) => (
                          <Link
                            key={productItem?._id}
                            to={`/products/productitem/${productItem?._id}`}
                            onClick={() =>
                              dispatch(productActions.setStockItem(productItem))
                            }
                            className="border bg-white w-52 mx-2 mb-3 cursor-pointer rounded-lg"
                          >
                            <div className="h-32 flex items-center justify-center">
                              {!productState?.product?.image?.url && (
                                <div className="bg-gray-200 rounded-md h-24 w-32 flex justify-center items-center me-2">
                                  <Typography className="whitespace-nowrap font-semibold text-2xl text-gray-500">
                                    {initials(productItem?.productItem?.name)}
                                  </Typography>
                                </div>
                              )}
                              {productState?.product?.image?.url && (
                                <img
                                  className="h-24 w-32"
                                  src={productState?.product?.image?.url}
                                  alt="pff"
                                />
                              )}
                            </div>
                            <hr />
                            <div className="font-semibold mt-2 mx-2 whitespace-nowrap truncate">
                              {productItem?.productItem?.name}
                            </div>
                            <div className="flex items-center ms-1">
                              <Typography>Stock</Typography>
                              <div className="font-semibold text-gray-800 text-sm ms-2">
                                {productItem?.stock}
                              </div>
                            </div>
                            <div className="flex justify-end text-gray-400 mb-2">
                              <NoteAltOutlinedIcon />
                              <DeleteOutlineOutlinedIcon />
                            </div>
                          </Link>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
            {Boolean(anchorEl) && (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setUpdate(true);
                    setAnchorEl(null);
                  }}
                >
                  <AutorenewIcon />
                  <Typography>Update</Typography>
                </MenuItem>
                <MenuItem onClick={() => {}}>
                  <SellIcon />
                  <Typography>Update Price</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(appActions.setIsDeleting(true));
                  }}
                >
                  <DeleteIcon />
                  <Typography>Delete</Typography>
                </MenuItem>
              </Menu>
            )}
            {update && (
              <UpdatePriceList
                open={update}
                productItem={priceList}
                close={() => setUpdate(false)}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {appState.openForm.open && <NewPriceList />}
    </Layout>
  );
};

export default ProductItems;
