import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import store from "store";
import { useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "./index.module.scss";
import logo from "../assets/images/logocems.png";
import { ReceiptItem } from "./ReceiptComponent";
import { numberWithCommas } from "../utils/functions";
import { AppState } from "../store/app";

const SalesReceipt = (props: any) => {
  const [date, setDate] = useState(
    new Date().toLocaleString().substring(0, 17)
  );
  const newPurchase = useSelector((state: any) => state.shop?.newPurchase);
  const isSubmitting = useSelector((state: any) => state.app?.isSubmitting);
  const appState: AppState = useSelector((state: any) => state.app);

  return (
    <>
      <Box className={styles.purchasesReceipt}>
        <h4 className={styles.receipt_title}>Sales Invoice</h4>
        <Paper elevation={2} className={styles.purchasesReceipt_header}>
          <Box>
            <img className={styles.receipt_logo} src={logo} alt="logo" />
          </Box>
          <Box>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              {store.get("user")?.company?.name}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TEL: {store.get("user").company?.contact}
            </Typography>
            <Typography
              className={`${styles.centerText} ${styles.receipt_text}`}
            >
              TIN: {store.get("user").company?.tinNumber}
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={2} className={styles.receipt_table}>
          {newPurchase?.items[0]?.supplier?._id && (
            <>
              <Typography
                className={`${styles.centerText} ${styles.receipt_text}`}
              >
                Supplier: {newPurchase?.items[0]?.supplier?.name}
              </Typography>
            </>
          )}
          <Paper sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Item
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  U.Price
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}
                >
                  Total
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {props?.items?.map((item: any, index: number) => (
            <ReceiptItem
              key={index}
              index={index}
              item={item.productItemName}
              quantity={item.quantity}
              price={item.unitPrice}
            />
          ))}
          <Typography className={styles.receiptTotal}>
            Total: {numberWithCommas(appState.total)} Rwf
          </Typography>
          {/* </Box> */}
        </Paper>
        <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
          Done at {date}
        </Typography>
      </Box>
      <Box className={styles.receiptActions}>
        <Button
          className={styles.receipt_print_btn}
          startIcon={<PrintIcon />}
          variant="contained"
        >
          <Typography>Print</Typography>
        </Button>
        {!isSubmitting && (
          <Button
            className={styles.receipt_save_btn}
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => {
              props?.onSave();
            }}
            variant="contained"
          >
            <Typography>Save</Typography>
          </Button>
        )}
        {isSubmitting && (
          <Button className={styles.receipt_save_btn} variant="contained">
            <CircularProgress
              size={30}
              color="inherit"
              className={styles.circularProgress}
            />
          </Button>
        )}
      </Box>
    </>
  );
};

export default SalesReceipt;
