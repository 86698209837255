import {
  exportExcel,
  requestFailed,
  setResponseAction,
} from "../../../utils/functions";
import { appActions } from "../../app";
import { shopActions } from "../../shop";
import { getShopsService, searchShopService } from "./shopServices";

// GET ALL
export const getShopsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getShopsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(shopActions.setShops(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(shopActions.setFetchedShops(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchShopsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getShopsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(shopActions.setShops(res.data));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportShopsAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getShopsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        exportExcel(res.data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchShopAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchShopService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(appActions.setUsers(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedStaff(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
