import { requestFailed, setResponseAction } from "../../../utils/functions";
import { productActions } from "..";
import { getProductPricesService } from "./productPriceServices";

// GET ALL
export const getProductPricesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(productActions.setFetchingProductPrices(true));
      const res = await getProductPricesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(productActions.setProductPrices(res.data));
      }
      dispatch(productActions.setFetchingProductPrices(false));
    } catch (err) {
      console.log(err);
    }
  };
};
