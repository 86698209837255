import moment from "moment";
import XLSX from "xlsx";
import { appActions } from "../store/app";
import { PurchaseItem } from "../interfaces/purchaseItem";

export const numberWithCommas = (x: number) => {
  x = Math.round(x);
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getNumberWithCommas = (x: string) => {
  return +x.replaceAll(",", "");
};

export const setResponseAction = (
  err: any,
  status?: string,
  message?: string
) => {
  return (dispatch: any) => {
    dispatch(
      appActions.setHttpResponse({
        statusCode: err?.response?.status,
        status: status || "fail",
        message:
          err?.response?.data?.error || err?.response?.data?.message || message,
      })
    );
  };
};

export const catchErrorMiddleware = (
  err: any,
  status?: string,
  message?: string
) => {
  return (dispatch: any) => {
    dispatch(
      appActions.setHttpResponse({
        statusCode: err?.response?.status,
        status: err?.response?.data?.status || status,
        message: err?.response?.data?.message || message,
      })
    );
  };
};

export const exportExcel = (Dataset: any, fileName: String) => {
  /* Create a worksheet */
  var ws = XLSX.utils.json_to_sheet(Dataset);

  /* Create a new empty workbook, then add the worksheet */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Test");

  /* Generate xlsx files */
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

// Function to export excel
export const exportStockExcel = (
  jsonData: any,
  sheets: any,
  excelName = "Sheet"
) => {
  /* Create a new empty workbook, then add the worksheet */
  var wb = XLSX.utils.book_new();
  for (var i = 0; i < sheets.length; i++) {
    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(jsonData[i]);

    XLSX.utils.book_append_sheet(wb, ws, `${sheets[i].name}`);
  }

  // /* Generate xlsx files */
  XLSX.writeFile(wb, `${excelName}.xlsx`);
};

export const generateGreetings = () => {
  var currentHour = +moment().format("HH");

  if (currentHour >= 3 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "Good Afternoon";
  } else if (currentHour >= 17 && currentHour < 20) {
    return "Good Evening";
  } else if (currentHour >= 20 && currentHour < 3) {
    return "Good Night";
  } else {
    return "Hello";
  }
};

export const renderMoney = (x: number) => {
  return numberWithCommas(x) + " RWF";
};

export const requestFailed = (res: any) => {
  return (
    res.response?.data.status.toString().startsWith("4") ||
    res.response?.data.status.toString().startsWith("5") ||
    res.message === "Network Error"
  );
};

export const calculateTotalPriceNumber = (list: any, type: string = "string") => {
  let sum = 0;
  list.forEach((item: any) => {
    sum += item.unitPrice * item.quantity;
  });

  return sum;
};

export const calculateTotalPrice = (list: any, type: string = "string") => {
  let sum = 0;
  list.forEach((item: any) => {
    sum += item.unitPrice * item.quantity;
  });

  return type === "string" ? numberWithCommas(sum) : sum;
};

export const initials = (name: string) => {
  return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`
};
