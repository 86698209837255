import { User } from "./user";
import i18n from "../i18n";

export interface ShopColumn {
  id: "name" | "address" | "status" | "category" | "action";
  label: string;
  minWidth?: number;
  align?: "center";
}

export const shopColumns: ShopColumn[] = [
  { id: "name", label: i18n.t("name"), align: "center" },
  { id: "address", label: i18n.t("address"), align: "center" },
  { id: "status", label: i18n.t("status"), align: "center" },
  { id: "category", label: i18n.t("category"), align: "center" },
  { id: "action", label: i18n.t("action"), align: "center" },
];

export interface ShopRow {
  name: string;
  address: string;
  status: string;
  category: string;
  action: any;
}

export interface Shop {
  _id: string;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
  address: string;
  status: string;
  shopCategory: string;
  tags: [string];
  createdBy: User;
  createdAt: string;
  updatedAt: string;
}
