import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import styles from "../index.module.scss";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormMultiSelect = (props: any) => {
  const appState = useSelector((state: any) => state.app);

  return (
    <>
      {appState.fetchingFormItems && (
        <Box>
          <Skeleton
            className={`${styles.form_input} ${styles.form_select_skeleton}`}
          />
        </Box>
      )}
      {!appState.fetchingFormItems && (
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={props.element.value}
          className={styles.formSelect}
          onChange={props.element.handleChange}
          input={
            <OutlinedInput
              label="Tag"
              inputProps={{
                className: styles.textField_input,
              }}
            />
          }
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.element.values.map((symptom: any) => (
            <MenuItem key={symptom._id} value={symptom.name}>
              <Checkbox checked={props.element.value.indexOf(symptom.name) > -1} />
              <ListItemText primary={symptom.name} />
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default FormMultiSelect;
