import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';

const LoadingContainer = () => {
  const appState = useSelector((state: any) => state.app);
  return (
    <>
      {(appState.isFetching || appState.drawerFetching) && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default LoadingContainer;
