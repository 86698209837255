import Layout from "../../components/Layout";
import { useState } from "react";
import ProfileSettings from "./ProfileSettings";
import ShopsSettings from "./ShopsSettings";
import UsersSettings from "./UsersSettings";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const pages = ["Profile", "Users", "Shops"];

  return (
    <Layout pages={pages} activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 0 && <ProfileSettings />}
      {activeTab === 1 && <UsersSettings />}
      {activeTab === 2 && <ShopsSettings />}
    </Layout>
  );
};

export default Settings;
