import { Box } from "@mui/material";
import styles from "../index.module.scss";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";

const FormSelect = (props: any) => {
  const appState = useSelector((state: any) => state.app);

  return (
    <>
      {appState.fetchingFormItems && (
        <Box>
          <Skeleton
            className={`${styles.form_input} ${styles.form_select_skeleton}`}
          />
        </Box>
      )}
      {!appState.fetchingFormItems && (
        <>
          <>
            <input
              onChange={(e) => {
                const shownVal = e.target.value;
                const index = props?.element?.values?.findIndex(
                  (val: any) =>
                    val.name === shownVal ||
                    val[props?.element?.valueLabel] === shownVal
                );
                if (index < 0) {
                  props?.element?.onChange(shownVal, false);
                } else {
                  props?.element?.onChange(props?.element?.values[index], true);
                }
              }}
              value={props?.element?.value}
              className={styles.formSelect}
              type="text"
              list={props?.element?.identifier}
            />
            <datalist id={props?.element?.identifier}>
              {props?.element?.values?.map((item: any, key: number) => (
                <option
                  key={key}
                  value={item.name || item[props?.element?.valueLabel] || item[props?.element?.valueLabel].earTag}
                />
              ))}
            </datalist>
          </>
        </>
      )}
    </>
  );
};

export default FormSelect;
