import HttpRequest from "../../utils/HttpRequest";
import { SERVER_URL } from "../../utils/constants";

export const getDashboardStatsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/dashboard/stats${query}`, token);
};

export const getTotalSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/sales/total-sales${query}`, token);
};

export const getStockValueService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/dashboard/stock-value${query}`, token);
};

export const getTopSellingService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/dashboard/top-selling${query}`, token);
};
