const translationsFr = {
  welcome: "Bienvenue",
  forms: "Formulaires",
  dashboard: "Tableau de bord",
  staff: "Personnel",
  search: "Recherche",
  exportExcel: "Exporter le fichier",
  months: "Mois",
  sale: "Vente",
  logout: "Déconnexion",
  report: "Rapport",
  client: "Client",
  paid: "Montant payé",
  addSale: "Ajouter une vente",
  kgUnitCost: "Coût unitaire en Kg",
  totalPrice: "Total",
  addNew: "Ajouter un nouveau",
  firstName: "Prénom",
  lastName: "Nom de famille",
  name: "Nom",
  nationalID: "Identité nationale",
  email: "Email",
  position: "Poste",
  gender: "Genre",
  dob: "Date de naissance",
  category: "Catégorie",
  action: "Action",
  delete: "Supprimer",
  view: "Voir",
  update: "Mettre à jour",
  cancel: "Annuler",
  confirmDelete: "Confirmer la suppression",
  confirmDeleteDesc: "Êtes-vous sûr de vouloir supprimer",
  deleteSuccess: "Supprimé avec succès !",
  male: "Homme",
  female: "Femme",
  addStaff: "Ajouter un nouveau personnel",
  updateStaff: "Mettre à jour le personnel",
  save: "Enregistrer",
  beginningDate: "Date de début",
  filter: "Filtrer",
  amount: "Montant",
  paidAmount: "Montant payé",
  createdBy: "Créé par",
  createdAt: "Créé le",
  updatedAt: "Mis à jour le",
  dateOfBirth: "Date de naissance",
  status: "Statut",
  photo: "Photo",
  work: "Travail",
  documentName: "Nom du document",
  documentType: "Type de document",
  document: "Document",
  add: "Ajouter",
  days: "Jours",
  cost: "Coût",
  remaining: "Restant",
  afterNoon: "Après-midi",
  morning: "Matin",
  L: "Litre",
  Kg: "Kilogramme",
  m: "Mètre",
  expense: "Dépense",
  paymentMethod: "Méthode de paiement",
  dontHaveAccountDesc: "Vous n'avez pas de compte ? Cliquez ici pour vous inscrire",
  forgotPassword: "Mot de passe oublié",
  rememberMe: "Se souvenir de moi",
  purchase: "Achat",
  sales: "Ventes",
  stock: "Stock",
  settings: "Paramètres",
  items: "Articles",
  profile: "Profil",
  language: "Langue",
  createAccount: "Créer un compte",
  accountCreated: "Compte créé avec succès !",
  incorrectCredentials: "Email ou mot de passe incorrect",
  totalSales: "Total des ventes",
  stockValue: "Valeur du stock",
  debts: "Dettes",
  credits: "Crédits",
  totalIncome: "Revenu total",
  topSellingProducts: "Produits les plus vendus",
  customers: "Clients",
  supplier: "Fournisseurs",
  products: "Produits",
  users: "Utilisateurs",
  profit: "Profit"
}


export default translationsFr;
