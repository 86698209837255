import * as React from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Steps,
  Typography,
} from "antd";
import dayjs from "dayjs";
import Storage from "../../utils/storage";
import { useEffect, useState } from "react";
import styles from "../../components/index.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import { AppState, appActions } from "../../store/app";
import { LayoutType, layout } from "../../components/GenericForm";
import PurchaseReceipt from "../../components/PurchaseReceipt";
import { Product } from "../../interfaces/product/product";
import { ProductItem } from "../../interfaces/product/productItem";
import {
  addPurchaseService,
  addToPurchaseListService,
  deletePurchaseItemService,
  updatePurchaseItemService,
  updatePurchaseService,
} from "../../store/purchase/purchaseService";
import { PurchasesState, purchasesActions } from "../../store/purchase";
import { fetchElementsAction } from "../../store/purchase/purchaseActions";
import { increaseSupplierDebtService } from "../../store/app/supplier/supplierService";
import CloseIcon from "@mui/icons-material/Close";
import { calculateTotalPriceNumber } from "../../utils/functions";
import { PurchaseItem } from "../../interfaces/purchaseItem";
import { primaryColor } from "../../assets/colors/colors";
import { LoadingButton } from "@mui/lab";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ShopState } from "../../store/shop";
import { ProductState } from "../../store/products";
import { ProductUnit } from "../../interfaces/product/productUnit";
import {
  getProductItemsAction,
  getProductsAction,
} from "../../store/products/product/productActions";
import { getProductPricesAction } from "../../store/products/productPrice/productPriceActions";
import { ProductPrice } from "../../interfaces/product/productPrice";
import { addProductPriceService } from "../../store/products/productPrice/productPriceServices";

export interface NewPurchaseItem {
  product: string;
  productItem: string;
  productItemName: string;
  quantity: number;
  totalPrice: number;
  unit: string;
  unitPrice: string;
}

export interface NewPurchaseProps {
  purchase?: any;
  close: Function;
  action?: string;
}

export default function NewPurchase(props: NewPurchaseProps) {
  const [formLoading, setFormLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [purchase, setPurchase] = useState<Product>();
  const [productItems, setProductItems] = useState<ProductItem[]>([]);
  const [productUnits, setproductUnits] = useState<any>([]);
  const [newPurchaseItems, setNewPurchaseItems] = useState<NewPurchaseItem[]>(
    []
  );
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const currentDate = dayjs();
  const [form] = Form.useForm();
  // const [purchasesItems, setPurchasesItems] = useState([] as NewPurchaseData[]);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [updatingItem, setUpdatingItem] = useState(false);
  const [index, setIndex] = useState(-1);
  const appState: AppState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const productState: ProductState = useSelector((state: any) => state.product);
  const purchaseState: PurchasesState = useSelector(
    (state: any) => state.purchase
  );
  const { Option } = Select;
  let price: ProductPrice | undefined;

  console.log("setting update purchase");

  const resetFields = (allFields: Boolean = false) => {
    form.resetFields([
      "product",
      "productItem",
      "quantity",
      "unit",
      "unitPrice",
      "totalPrice",
    ]);
  };

  useEffect(() => {
    if (!purchaseState.fetchedFormElements) {
      dispatch(fetchElementsAction(``, token));
    }
    if (!productState.fetchedProducts) {
      dispatch(getProductsAction(``, token));
    }

    if (!productState.fetchedProductItems) {
      dispatch(getProductItemsAction(``, token));
    }

    if (!productState.fetchedProductPrices) {
      dispatch(
        getProductPricesAction(
          `?company=${Storage.getUser()?.company?._id}&isActive=true`,
          token
        )
      );
    }

    if (props.action === "update") {
      const purchaseItems = props.purchase?.list?.map((item: PurchaseItem) => {
        return {
          product: item.product,
          productItem: item?.productItem?._id,
          productItemName: item?.productItem?.name,
          quantity: item?.quantity,
          totalPrice: item?.quantity * item?.unitPrice,
          unit: item?.unit,
          unitPrice: item?.unitPrice,
        };
      });

      setNewPurchaseItems(purchaseItems);
      dispatch(
        appActions.setTotal(calculateTotalPriceNumber(props.purchase?.list))
      );
      setPurchase(props.purchase);
    }
    return () => {
      resetFields(true);
    };
  }, []);

  const addPurchaseHandler = async () => {
    if (purchase?._id !== undefined) {
      let payload: any = {
        status: "Completed",
        amountPaid: [{ value: form.getFieldValue("amountPaid") }],
        date: form.getFieldValue("date"),
        isCredit:
          +form.getFieldValue("amountPaid") < +form.getFieldValue("total"),
      };
      if (form.getFieldValue("supplier")) {
        payload.supplier = form.getFieldValue("supplier");
      }
      if (+form.getFieldValue("amountPaid") < +form.getFieldValue("total")) {
        await increaseSupplierDebtService(
          form.getFieldValue("supplier"),
          {
            amount:
              +form.getFieldValue("total") - +form.getFieldValue("amountPaid"),
          },
          token
        );
      }

      setFormLoading(true);
      await updatePurchaseService(purchase?._id, payload, token);
      setFormLoading(false);

      //DISPLAY SUCCESS MESSAGE TOAST
      dispatch(
        appActions.setHttpResponse({
          statusCode: 200,
          status: "success",
          message: "Purchase added Successfully!",
        })
      );
      dispatch(appActions.resetTotal());

      // NAVIGATE
      dispatch(purchasesActions.setFetchedPurchases(false));
      props.close();
    }
  };

  const validateSoldQuantity = (_: any, value: string) => {
    if (parseFloat(value) <= 0) {
      return Promise.reject("Quantity sold must be greater than 0");
    } else if (parseFloat(value) > +form.getFieldValue("stock")) {
      return Promise.reject("Quantity sold exceeding available stock");
    }
    return Promise.resolve();
  };

  const onProductItemChanged = (value: any) => {
    price = productState?.productPrices.find(
      (currentItem: ProductPrice) =>
        currentItem?.productItem?._id === value &&
        currentItem.type === "buyingPrice"
    );
    form.setFieldValue("unitPrice", price?.value ?? 0);
  };

  const getProductItems = (productId: string) => {
    const selectedProductItems = productState?.productItems.filter(
      (productItem: ProductItem) => productItem?.product?._id === productId
    );
    const selectedUnits = productState?.productUnits.filter(
      (productUnit: ProductUnit) => productUnit?.product === productId
    );
    setProductItems(selectedProductItems);
    setproductUnits(selectedUnits[0]?.formular);
  };

  const onItemClicked = async (inIndex: number) => {
    setUpdatingItem(true);
    getProductItems(newPurchaseItems[inIndex].product);
    setIndex(inIndex);
    form.setFieldsValue({
      product: newPurchaseItems[inIndex].product,
      productItem: newPurchaseItems[inIndex].productItem,
      quantity: newPurchaseItems[inIndex].quantity,
      unit: newPurchaseItems[inIndex].unit,
      unitPrice: newPurchaseItems[inIndex].unitPrice,
      totalPrice:
        newPurchaseItems[inIndex].quantity *
        +newPurchaseItems[inIndex].unitPrice,
    });
  };

  const updatePurchaseItem = async () => {
    if (index >= 0) {
      console.log("we have an item ", props.purchase?.list[index]);
      setFormLoading(true);
      await updatePurchaseItemService(
        props.purchase?.list[index]._id,
        form.getFieldsValue(),
        token
      );
      setFormLoading(false);

      newPurchaseItems[index] = {
        ...newPurchaseItems[index],
        quantity: +form.getFieldValue("quantity"),
        unit: form.getFieldValue("unit"),
        unitPrice: form.getFieldValue("unitPrice"),
        totalPrice:
          +form.getFieldValue("quantity") * +form.getFieldValue("unitPrice"),
      };
      setNewPurchaseItems([...newPurchaseItems]);
    }
  };

  const deletePurchaseItem = async () => {
    if (index >= 0) {
      console.log("we have an item ", props.purchase?.list[index]);
      setDeleting(true);
      await deletePurchaseItemService(
        props.purchase?._id,
        props.purchase?.list[index]._id,
        token
      );
      setDeleting(false);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setFormLoading(true);
      if (productUnits !== undefined) {
        let unit = productUnits.filter(
          (productUnit: any) => productUnit._id === values.unit
        );
        values.unit = unit[0].label;
      }

      let item = productItems.filter(
        (productItem: any) => productItem._id === values.productItem
      );
      values.productItemName = item[0].name;
      values.shop = shopState.shop?._id;
      values.company = Storage.getUser()?.company?._id;
      if (newPurchaseItems.length === 0) {
        let purchase = await addPurchaseService(
          {
            shop: shopState.shop?._id,
            company: Storage.getUser()?.company?._id,
          },
          token
        );

        console.log("resssssssssssssssss 0", purchase);

        setPurchase(purchase.data);
        values.purchase = purchase.data._id;
        await addToPurchaseListService(purchase.data._id, values, token);
      }
      setNewPurchaseItems([...newPurchaseItems, values]);
      dispatch(appActions.addToTotal(values.totalPrice));
      if (purchase?._id) {
        values.purchase = purchase?._id;
        let data = await addToPurchaseListService(purchase?._id, values, token);
      }

      if (price?.value !== values.unitPrice) {
        let data = {
          value: values.unitPrice,
          shop: shopState.shop?._id,
          company: Storage.getUser()?.company?._id,
          type: "buyingPrice",
          product: values.product,
          productItem: values.productItem,
        };
        addProductPriceService(data, token);
      }

      setFormLoading(false);
      resetFields();
    } catch (error: any) {
      console.log("errror ", error?.response);
    }
  };

  const formElements = [
    {
      name: "product",
      label: "Product",
      type: "select",
      loading: productState.fetchingProducts,
      options: productState?.products,
      selectLabel: "productName",
      handleSelectChange: async (value: any) => {
        let products = productState?.products.filter(
          (product: Product) => product._id === value
        );
        await getProductItems(products[0]._id);
      },
    },
    {
      name: "productItem",
      label: "Item",
      type: "select",
      loading: productState.fetchingProductItems,
      options: productItems,
      handleSelectChange: onProductItemChanged,
      rules: [{ required: true, message: "Item is required" }],
    },
    {
      name: "unit",
      label: "Unit",
      type: "select",
      options: productUnits,
      selectLabel: "label",
      selectValue: "label",
    },
    {
      name: "unitPrice",
      label: "Unit Price",
      type: "number",
      loading: productState.fetchingProductPrices,
      rules: [
        { required: true, message: "Please enter the unit buying Price" },
      ],
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue("unitPrice"),
        });
      },
      rules: [
        { required: true, message: "Please enter the quantity sold" },
        { validator: validateSoldQuantity },
      ],
    },
    {
      name: "totalPrice",
      label: "Total Price",
      type: "number",
      onChange: (value: any) => {
        form.setFieldsValue({
          unitPrice: +value / +form.getFieldValue("quantity"),
          totalPaid: +value,
        });
      },
    },
  ];

  const secondFormElements = [
    {
      name: "total",
      label: "Total Price",
      type: "number",
      disabled: true,
      rules: [{ required: true, message: "Please enter the amount received" }],
    },
    {
      name: "amountPaid",
      label: "Amount Paid",
      type: "number",
      rules: [{ required: true, message: "Please enter the amount received" }],
    },
    {
      name: "supplier",
      label: "Supplier",
      loading: purchaseState.fetchingFormElements,
      type: "select",
      options: purchaseState.suppliers,
      rules: [{ required: true, message: "Please enter the supplier" }],
      onChange: () => {
        console.log();
      },
    },
    {
      name: "date",
      label: "Start Date",
      type: "date",
    },
  ];

  return (
    <div>
      <div className="flex items-center ms-10 mt-8">
        <div
          className="bg-slate-300 p-3 rounded-md me-4 cursor-pointer"
          onClick={() => {
            dispatch(appActions.resetTotal());
            props.close();
          }}
        >
          <ArrowBackIcon />
        </div>
        <Typography className="font-bold text-xl">Add New Purchase</Typography>
      </div>
      <Grid container className="mt-8">
        <Grid item xs={8}>
          <Box className={styles.genericForm_column}>
            <div className="bg-white pt-4 rounded-md">
              <div className="w-1/4 mx-auto">
                <Steps
                  direction="horizontal"
                  current={currentStep}
                  items={[{}, {}]}
                />
              </div>
              <Form
                {...layout}
                layout={formLayout}
                form={form}
                name="control-hooks"
                onFinish={onSubmit}
                className="my_form"
              >
                <Box style={{ margin: "0 auto" }}>
                  {currentStep === 0 &&
                    formElements.map((element: any) => (
                      <Box
                        key={element.name}
                        className={styles.forms_input_horizontal}
                      >
                        <Form.Item
                          name={element.name}
                          label={element.label}
                          rules={element.rules ? element.rules : []}
                        >
                          {element.type === "number" && (
                            <InputNumber
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === "text" && (
                            <Input disabled={element?.disabled ?? false} />
                          )}
                          {element.type === "date" && (
                            <DatePicker defaultValue={currentDate} />
                          )}
                          {element.type === "select" && (
                            <>
                              {element?.loading !== true && (
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={element?.handleSelectChange}
                                  filterOption={(input, option) =>
                                    (option?.children?.toString() as string)
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {element?.options?.map((opt: any) => (
                                    <Option key={opt._id} value={opt._id}>
                                      {opt.name ??
                                        opt[`${element.selectLabel}`]}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                              {element?.loading === true && (
                                <Skeleton
                                  className={`${styles.form_input} ${styles.form_select_skeleton}`}
                                />
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                  {currentStep === 1 &&
                    secondFormElements.map((element: any) => (
                      <Box
                        key={element.name}
                        className={styles.forms_input_horizontal}
                      >
                        <Form.Item
                          name={element.name}
                          label={element.label}
                          rules={element.rules ? element.rules : []}
                        >
                          {element.type === "number" && (
                            <InputNumber
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === "text" && (
                            <Input disabled={element?.disabled ?? false} />
                          )}
                          {element.type === "date" && (
                            <DatePicker defaultValue={currentDate} />
                          )}
                          {element.type === "select" && (
                            <>
                              {element?.loading !== true && (
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={element?.handleSelectChange}
                                  filterOption={(input, option) =>
                                    (option?.children?.toString() as string)
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {element?.options?.map((opt: any) => (
                                    <Option key={opt._id} value={opt._id}>
                                      {opt.name ??
                                        opt[`${element.selectLabel}`]}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                              {element?.loading === true && (
                                <Skeleton
                                  className={`${styles.form_input} ${styles.form_select_skeleton}`}
                                />
                              )}
                            </>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                </Box>
                {currentStep === 0 && (
                  <Box className={styles.center}>
                    {!updatingItem && (
                      <>
                        <Button
                          type="submit"
                          className={styles.confirmButton}
                          variant="contained"
                        >
                          {formLoading ? "Adding..." : "Add"}
                        </Button>
                        <Button
                          className={styles.confirmButton}
                          variant="contained"
                          onClick={() => {
                            form.setFieldsValue({
                              total: appState.total,
                              amountPaid: appState.total,
                            });
                            setCurrentStep(1);
                          }}
                        >
                          Continue
                        </Button>
                      </>
                    )}
                    {updatingItem && (
                      <div className={styles.center}>
                        <LoadingButton
                          className={styles.confirmButton}
                          variant="contained"
                          loading={formLoading}
                          loadingPosition="center"
                          loadingIndicator={<LoadingIndicator />}
                          onClick={() => updatePurchaseItem()}
                        >
                          Update
                        </LoadingButton>
                        <LoadingButton
                          className={styles.confirmButton}
                          variant="contained"
                          loading={deleting}
                          loadingPosition="center"
                          loadingIndicator={<LoadingIndicator />}
                          onClick={async () => {
                            await deletePurchaseItem();
                          }}
                        >
                          Delete
                        </LoadingButton>
                        {/* <Button
                          className={styles.confirmButton}
                          variant="contained"
                          onClick={() => {
                            setUpdatingItem(false);
                            resetFields();
                          }}
                        >
                          Cancel
                        </Button> */}
                        <div
                          className="bg-gray-200 p-1 ms-2 rounded-lg mt-3 cursor-pointer"
                          onClick={() => {
                            setUpdatingItem(false);
                            setIndex(-1);
                            resetFields();
                          }}
                        >
                          <CloseIcon style={{ color: primaryColor }} />
                        </div>
                      </div>
                    )}
                  </Box>
                )}
                {currentStep === 1 && (
                  <Box className={styles.center}>
                    <Button
                      className={styles.confirmButton}
                      variant="contained"
                      onClick={() => addPurchaseHandler()}
                    >
                      {formLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </Box>
                )}
              </Form>
            </div>
            {/* {newSaleElements.map((element: any, index: number) => {
                  return <FormInputs key={index} element={element} style="horizontal" />;
                })} */}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <PurchaseReceipt
            title="Purchase"
            onItemClicked={onItemClicked}
            items={newPurchaseItems}
            onSave={addPurchaseHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
}
