import React from "react";
import { BrowserRouter } from "react-router-dom";
import ResponseDialog from "./components/ResponseDialog";
import MainRouter from "./routes/MainRouter";

function App() {
  return (
    <BrowserRouter>
      <MainRouter />
      <ResponseDialog />
    </BrowserRouter>
  );
}

export default App;
