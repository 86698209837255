import SearchIcon from "@mui/icons-material/Search";

export interface SearchComponentProps {
  placeholder: string;
  setSearchText: Function;
}

const SearchComponent2 = (props: SearchComponentProps) => {

  return (
    <div className="bg-white w-[35vw] flex border border-blue-500 rounded-3xl overflow-hidden">
      <input
        className="basis-11/12 rounded-3xl ps-4 outline-none"
        placeholder={props.placeholder}
        type="text"
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          props.setSearchText(event.target.value);
        }}
      />
      <div className="basis-1/12 bg-blue-500 flex justify-center p-2 items-center">
        <SearchIcon style={{ color: "white" }} />
      </div>
    </div>
  );
};

export default SearchComponent2;
