import { Button, Menu, MenuItem } from "@mui/material";
import { Form, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShopsAction } from "../../store/app/shop/shopActions";
import Storage from "../../utils/storage";
import { AppState, appActions } from "../../store/app";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styles from "../index.module.scss";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LoadingContainer from "../../components/LoadingContainer";
import TableComponent from "../../components/TableComponent";
import { Shop, ShopRow, shopColumns } from "../../interfaces/shop";
import SearchComponent from "../../components/SearchComponent";
import DynamicListView from "../../components/DynamicListView";
import NewShop from "./NewShop";
import SearchComponent2 from "../../components/SearchComponent2";
import { ShopState, shopActions } from "../../store/shop";
import ConfirmModal from "../../components/ConfirmModal";
import { deleteAction } from "../../store/app/appActions";
import { deleteShopService } from "../../store/app/shop/shopServices";

const ShopsSettings = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addShop, setAddShop] = useState(false);
  const [viewGrid, setViewGrid] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [shop, setShop] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const appState: AppState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);

  useEffect(() => {
    if (!shopState.fetchedShops) {
      dispatch(
        getShopsAction(`?company=${Storage.getUser()?.company?._id}`, token)
      );
    }
  }, [shopState.fetchedShops]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedStaff(false));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(appActions.setFetchedStaff(false));
  };

  const deleteShopHandler = () => {
    dispatch(
      deleteAction(deleteShopService, shop?._id, token, "Shop", () => {
        // props.close();
        dispatch(shopActions.setFetchedShops(false));
      })
    );
  };

  const createData = (shop: Shop): ShopRow => {
    return {
      name: shop.name,
      address: shop.address,
      status: shop.status,
      category: shop?.shopCategory?.toUpperCase(),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setShop(shop);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState.shops?.map((shop: Shop) => createData(shop));

  return (
    <>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="flex items-center justify-between my-10">
            <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
            <SearchComponent2
              placeholder="Search Shop"
              setSearchText={setSearchText}
            />
            <Button
              startIcon={<AddCircleOutlineIcon />}
              className={styles.primaryButton}
              onClick={() => setAddShop(true)}
            >
              Add Shop
            </Button>
          </div>

          <TableComponent
            rows={rows}
            columns={shopColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {addShop && (
            <NewShop
              open={addShop}
              close={() => setAddShop(false)}
              title={"Add Shop"}
              shop={shop}
              onSubmit={() => {}}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteShopHandler()}
              content={`Please confirm to delete ${shop?.name} Product`}
            />
          )}
          {Boolean(anchorEl) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setAddShop(true);
                }}
              >
                <AutorenewIcon />
                <Typography>Update</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteOutlineOutlinedIcon />
                <Typography>Delete</Typography>
              </MenuItem>
            </Menu>
          )}
        </>
      )}
    </>
  );
};

export default ShopsSettings;
