import { Box, Button, Dialog, DialogContent } from "@mui/material";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import FormHeader from "./FormHeader";
import {
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  FormInstance,
} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "../i18n";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  form: FormInstance<any>;
  formElements: any;
  onSubmit: (values: any) => void;
  styles?: string;
  confirmText?: string;
}

export type LayoutType = Parameters<typeof Form>[0]["layout"];

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const GenericForm = (props: GenericFormProps) => {
  const currentDate = dayjs();
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <Dialog
      classes={{
        paper:
          props?.styles === "horizontal"
            ? styles.genericForm
            : styles.genericFormVertical,
      }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title={props.title} close={props.close} />
      <DialogContent>
        <Form
          {...layout}
          layout={formLayout}
          form={props.form}
          name="control-hooks"
          onFinish={props.onSubmit}
          // style={{ maxWidth: 600 }}
          className="my_form"
        >
          {props.formElements.map((element: any) => (
            <Box
              key={element.name}
              className={
                props?.styles === "horizontal"
                  ? styles.forms_input_horizontal
                  : styles.forms_input_vertical
              }
            >
              <Form.Item
                name={element.name}
                label={element.label}
                rules={element.rules ? element.rules : []}
              >
                {element.type === "number" && <InputNumber />}
                {element.type === "text" && (
                  <Input disabled={element?.disabled ?? false} />
                )}
                {element.type === "password" && (
                  <Input.Password placeholder="• • • • • • • • •" />
                )}
                {element.type === "date" && (
                  <DatePicker defaultValue={currentDate} />
                )}
                {element.type === "select" && (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={element?.handleSelectChange}
                    filterOption={(input, option) =>
                      (option?.children?.toString() as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {element?.options?.map((opt: any) => (
                      <Option
                        key={opt._id}
                        value={opt._id ?? opt[`${element.selectValue}`]}
                      >
                        {opt.name ?? opt[`${element.selectLabel}`]}
                      </Option>
                    ))}
                  </Select>
                )}
                {element.type === "tags" && (
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    options={element.options}
                  />
                )}
              </Form.Item>
            </Box>
          ))}
          <Box className={styles.form_action}>
            <Button
              onClick={() => props?.close()}
              className={styles.form_cancelButton}
              variant="contained"
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              className={styles.form_confirmButton}
              loading={isSubmitting}
              size="large"
            >
              {t("save")}
            </LoadingButton>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default GenericForm;
