import { dashboardActions } from ".";
import { requestFailed, setResponseAction } from "../../utils/functions";
import { getTotalCreditService } from "../app/customer/customerService";
import { getTotalDebtService } from "../app/supplier/supplierService";
import {
  getDashboardStatsService,
  getStockValueService,
  getTopSellingService,
  getTotalSalesService,
} from "./dashboardServices";

export const getDashboardStatsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingStats(true));
      const res = await getDashboardStatsService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setStats(res.data));
      }
      dispatch(dashboardActions.setFetchingStats(false));
      dispatch(dashboardActions.setFetchedStats(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getTotalSalesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingTotalSales(true));
      const res = await getTotalSalesService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setTotalSales(res.data));
      }
      dispatch(dashboardActions.setFetchingTotalSales(false));
      dispatch(dashboardActions.setFetchedTotalSales(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getStockValueAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingStockValue(true));
      const res = await getStockValueService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setStockValue(res.data.stockValue));
      }
      dispatch(dashboardActions.setFetchingStockValue(false));
      dispatch(dashboardActions.setFetchedStockValue(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getTopSellingProductsAction = (
  query: string,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingTopSelling(true));
      const res = await getTopSellingService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setTopSellingProducts(res.data));
      }
      dispatch(dashboardActions.setFetchingTopSelling(false));
      dispatch(dashboardActions.setFetchedTopSelling(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getTotalDebtAction = (
  query: string,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingTotalDebt(true));
      const res = await getTotalDebtService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setTotalDebt(res.data.totalDebt));
      }
      dispatch(dashboardActions.setFetchingTotalDebt(false));
      dispatch(dashboardActions.setFetchedTotalDebt(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};

export const getTotalCreditAction = (
  query: string,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(dashboardActions.setFetchingTotalCredit(true));
      const res = await getTotalCreditService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, "fail", res.message));
      } else {
        dispatch(dashboardActions.setTotalCredit(res.data.totalCredit));
      }
      dispatch(dashboardActions.setFetchingTotalCredit(false));
      dispatch(dashboardActions.setFetchedTotalCredit(true));
    } catch (error: any) {
      console.log(error);
    }
  };
};